.groupTravelWrapper {
  width: 95%;
  border: 2px solid rgb(99, 120, 189);
  border-radius: 10px;
  padding: 2vw;
  margin-left: auto;
  margin-right: auto;
}

/* ##### 23-10-16 Komarov */
.purecontentPage .groupTravelblocks div {
  padding: 0;
  min-height: 2vh;
}

.purecontentPage .groupTravelblocks_2 div,
.purecontentPage .groupTravelblocks_2_accommodation div,
.purecontentPage .groupTravelblocks_4 div,
.purecontentPage .groupTravelblocks_5 div,
.purecontentPage .groupTravelMultipleCities div {
  /* ##### 23-10-16 Komarov */
  margin-top: 0.25vh;
  margin-bottom: 0.25vh;
  padding: 0;
  min-height: 2vh;
}

/* ##### 23-10-16 Komarov */
.purecontentPage .groupTravelMultipleServices div {
  margin-top: 0.25vh;
  margin-bottom: 0.25vh;
  padding: 0;
  min-height: 2vh;
}

.purecontentPage .groupTravelblocks {
  border: 1px solid lightblue;
  border-radius: 10px;
  background: rgb(248, 238, 238);
  margin-top: 1vh;
  display: grid;
  align-items: center;
  grid-template-columns: 40% 40% 20%;
}

/* ##### 23-10-16 Komarov */
.purecontentPage .groupTravelMultipleServices {
  border: 1px solid lightblue;
  border-radius: 10px;
  background: rgb(248, 238, 238);
  margin-top: 1vh;
  padding-right: 0.8vh;
  /* ##### 23-10-11 Komarov */
  display: flex;
  align-items: center;
  /* ##### 23-10-11 Komarov */
}

.purecontentPage .groupTravelblocks_2 {
  border: 1px solid lightblue;
  border-radius: 10px;
  background: rgb(248, 238, 238);
  margin-top: 1vh;
  display: grid;
  align-items: center;
  grid-template-columns: 30% 20% 25% 25%;
}

.purecontentPage .groupTravelblocks_2_accommodation {
  border: 1px solid lightblue;
  border-radius: 10px;
  background: rgb(248, 238, 238);
  margin-top: 1vh;
  display: grid;
  align-items: center;
  grid-template-columns: 30% 15% 15% 12% 8% 12% 8%;
}

.purecontentPage .groupTravelblocks_3 {
  border: 1px solid lightblue;
  border-radius: 10px;
  background: rgb(248, 238, 238);
  margin-top: 1vh;
  display: grid;
  align-items: center;
  grid-template-columns: 15% 28% 17% 30%;
}

/* ##### 25-01-02 Komarov */
/* .purecontentPage .form-block-1-f {
  border: 1px solid lightblue;
  border-radius: 10px;
  background: rgb(248, 238, 238);
  margin-top: 1vh;
  padding-right: 0.8vh;
  display: flex;
  align-items: center;
} */

/* ##### 25-01-02 Komarov */
.purecontentPage .groupTravelblocks_4 {
  border: 1px solid lightblue;
  border-radius: 10px;
  background: rgb(248, 238, 238);
  margin-top: 1vh;
  display: grid;
  align-items: center;
  grid-template-columns: 30% 20% 35% 15%;
}

.purecontentPage .groupTravelblocks_5 {
  border: 1px solid lightblue;
  border-radius: 10px;
  background: rgb(248, 238, 238);
  margin-top: 1vh;
  display: grid;
  align-items: center;
  grid-template-columns: 15% 19% 13% 20% 13% 20%;
}

/* ##### 23-10-16 Komarov */
.purecontentPage .groupTravelblocks span,
.purecontentPage .groupTravelblocks_2 span,
.purecontentPage .groupTravelblocks_2_accommodation span,
.purecontentPage .groupTravelblocks_3 span,
.purecontentPage .form-block-lf span,
.purecontentPage .groupTravelblocks_4 span,
.purecontentPage .groupTravelblocks_5 span,
.purecontentPage .groupTravelMultipleServices span {
  font-weight: bold;
  color: rgb(13, 3, 70);
  font-size: 14px;
  margin-left: 0.8vw;
  margin-right: 0.8vw;
}

.groupTravelWrapper h3 {
  padding: 1vw;
  border: 2px solid rgb(206, 207, 209);
  border-radius: 0.5vw;
  /* ##### 24-09-23 Komarov */
  font-family: Arial, sans-serif;
  color: rgb(17, 17, 139);
  font-size: 20px;
  font-weight: bold;
  background-color: #d7ebfc;
  /* ##### 23-09-06 Komarov */
  text-indent: 1.3em;
}

@counter-style thumbs {
  system: cyclic;
  symbols: "\2714";
  suffix: " ";
}

/* ##### 24-12-19 Komarov */
ul.GroupTravelFormBlockUl {
  list-style: inside;
}

.groupTravelWrapper li {
  padding: 0.3vw;
  border: 2px solid rgb(206, 207, 209);
  border-radius: 0.5vw;
  /* ##### 24-09-23 Komarov */
  font-family: Arial, sans-serif;
  color: rgb(17, 17, 139);
  font-size: 15px;
  font-weight: bold;
  background-color: #d7ebfc;
  width: 80%;
}

/*##### 24-12-19 Komarov*/
/*.groupTravelSubmitButton {
  background: #009FE3;
  color: white;
  border: 1px solid grey;
  border-radius: 2px;
  font-weight: bold;
  font-size: 90%;
  width: 25%;
  text-align: center;
  padding-bottom: 2vh;
  padding-top: 2vh;
  margin-top: 25px;
  margin-bottom: 8px;
}*/

.groupTravel {
  margin-top: 25px;
  margin-bottom: 8px;
}

.groupTravelAddButton {
  display: flex;
  align-items: flex-end;
  grid-column: 3;
  grid-row: 2;
}

.groupTravelAddButton button {
  background: #009FE3;
  color: white;
  border: 1px solid grey;
  border-radius: 5px;
  font-weight: bold;
  font-size: 80%;
  min-width: 5vw;
  height: 32px;
  overflow: hidden;
  text-align: center;
}

/*##### 24-12-19 Komarov*/
/*.groupTravelSubmitButton:hover {
  background: darkblue;
}*/

.groupTravelMonth div.ant-picker-input input::placeholder {
  font-size: 13px;
  color: rgb(13, 3, 70);
  font-weight: bold;
}