/* ##### 24-11-01 Komarov */
ul.TopToursContentsUl {
  padding-left: 0;
  padding-right: 0;
  list-style: none;
}

/* ##### 23-04-19 Komarov */
li.RatesGridHeader {
  margin: inherit;
}

/* ##### 24-11-01 Komarov */
li.TopToursContentsLi {
  list-style: none;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;

}

.TopToursWrapper {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.TopToursWrapper:hover {
  cursor: pointer;
}

.TopToursImage {
  height: 270px;
  border-radius: 5px;
  /* ##### 23-03-30 Komarov */
  object-fit: cover;
}

.TopToursTitle h4 {
  color: #003057;
  /* ##### 24-09-23 Komarov */
  font-family: 'Arial Narrow', sans-serif;
  font-weight: bold;
  text-align: center;
  padding: 0.5vw;
  font-size: 100%;
}

.TopToursTitle {
  background-color: #BCD7EE;
  display: block;
  /* ##### 24-08-14 Komarov */
  height: 7vh;
  overflow: hidden;
  border-radius: 5px;
  margin-top: 0.2vh;
  margin-bottom: 2vh;
  margin-left: auto;
  margin-right: auto;
}

/* ##### 23-03-30 Komarov */
.TopToursTitleMobile h4 {
  color: #003057;
  /* ##### 24-09-23 Komarov */
  font-family: 'Arial Narrow', sans-serif;
  font-weight: bold;
  text-align: center;
  padding: 0.5vw;
  font-size: 100%;
}

.TopToursTitleMobile {
  background-color: #BCD7EE;
  display: block;
  height: 9vh;
  overflow: hidden;
  border-radius: 5px;
  margin-top: 0.2vh;
  margin-bottom: 2vh;
}

/* ##### 23-04-04 Komarov */
.TopToursTitleTablet {
  background-color: #BCD7EE;
  display: block;
  /* ##### 23-11-08 Komarov */
  height: 13vh;
  overflow: hidden;
  border-radius: 5px;
  margin-top: 0.2vh;
  margin-bottom: 2vh;
}

/* ##### 23-04-04 Komarov */
.TopToursTitleTablet h4 {
  color: #003057;
  /* ##### 24-09-23 Komarov */
  font-family: 'Arial Narrow', sans-serif;
  font-weight: bold;
  text-align: center;
  padding: 0.5vw;
  font-size: 100%;
}

.SmallerTopToursWrapper {
  margin-left: auto;
  margin-right: auto;
}

.SmallerTopToursImage {
  height: 270px;
  border-radius: 5px;
}

.SmallerTopToursTitle {
  background-color: #BCD7EE;
  display: block;
  height: 8vh;
  overflow: hidden;
  border-radius: 5px;
  margin-top: 0.2vh;
  margin-bottom: 2vh;
}

/* ##### 23-04-07 Komarov */
.SmallerTopToursTitle h4 {
  color: #003057;
  /* ##### 24-09-23 Komarov */
  font-family: 'Arial Narrow', sans-serif;
  font-weight: bold;
  text-align: center;
  padding: 0.5vw;
}

.frontPageArticle {
  display: flex;
  color: rgb(3, 3, 63);
  justify-content: center;
  text-align: justify;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2vh;
}

.frontPageArticle span {
  font-weight: bold;
  margin-top: 1vw
}

.BodyPageClass {
  display: flex;
  flex-direction: column;
}

.frontPageBanner:hover {
  cursor: pointer;
}

.TopHotelsClick:hover {
  cursor: pointer;
}