.ChoiceBlockWrapper {
  display: grid;
  grid-template-columns: repeat(2, 20vw);
}

.HotelPaxChoiceWrapper {
  display: flex;
  /* ##### 23-11-15 Komarov */
  flex-direction: column;
  /* ##### 24-09-23 Komarov */
  font-Family: Arial, sans-serif;
  justify-content: center;
}


.HotelPaxChoiceWrapper h4 {
  color: rgb(75, 73, 73);
  /* ##### 24-09-23 Komarov */
  font-family: Arial, sans-serif;
  cursor: pointer;
  margin: auto;
}

.HotelPaxResult {
  border-radius: 2px;
  height: 32px;
  width: 100%;
  padding: 3px 11px;
  background-color: white;
}

.HotelPaxResultSmallScreen {
  border-bottom: 0.5px solid grey;
  border-radius: 2px;
  /* ##### 23-05-05 Komarov */
  height: fit-content;
  width: fit-content;
  padding: 3px 11px;
  background-color: white;
}

.DropdownButton {
  margin-top: 0.5vh;
  background-color: white;
  border: 2px solid rgb(177, 174, 174);
  border-radius: 5px;
  min-width: 200px;
}

.DropdownItem {
  font-weight: bold;
  display: flex;
  flex-direction: column;
  color: #102D69;
}

.DropdownItem:hover {
  background-color: #d7ebfc;
  text-decoration: none;
}

.PopUpNotActive {
  display: none;
}

/* ##### 24-01-30 Komarov */
.PopUpActive-PageDevices {
  display: block;
  position: absolute;
  background-color: white;
  margin-top: 5vh;
  z-index: 3000;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 15px;
}

.PopUpButton {
  background: #009FE3;
  color: white;
  border: 1px solid grey;
  border-radius: 2px;
  font-size: 19px;
  /* ##### 23-11-15 Komarov */
  height: 50px;
  text-align: center;

}

.MinusInner {
  background-Color: #009FE3;
  border: 0.8vw solid #009FE3;
  border-radius: 5vw;
  font-size: 15px;
  font-weight: bolder;
  width: 40px;
  height: 40px
}

.PlusInner {
  background-Color: #009FE3;
  border: 0.8vw solid #009FE3;
  border-radius: 5vw;
  font-size: 15px;
  font-weight: bolder;
  width: 40px;
  height: 40px
}

.DownOutlined {
  color: #bfbfbf;
  font-size: 13px;
  padding-left: 5px;
  /* @@@@@ 22-09-20 Komarov: добавил отступ, чтобы стрелочка не сползала вниз после отображения страницы SSR */
  /* ##### 24-03-12 Komarov */
}

/* ##### 22-10-25 Komarov */
.DownOutlined_SmallPortrait {
  color: #bfbfbf;
  font-size: 1rem;
  padding-left: 5px;
  /* @@@@@ 22-09-20 Komarov: добавил отступ, чтобы стрелочка не сползала вниз после отображения страницы SSR */
  /* ##### 23-11-15 Komarov */
  margin-bottom: 0.9vh;
}

/* ##### 23-05-05 Komarov */
/*TODO: Проверить использование и нет ли с этим ошибок*/
#root>header>div.HotelDetailsWrapper>div:nth-child(11)>h3.Tablet_greaterThan_425-lessThanOrEqual_768.SearchDetailsSmallScreen>h4:nth-child(3)>div>div.lessThanOrEqual-desktopMinWidth_1024.HotelPaxChoiceWrapper>div.lessThanOrEqual-desktopMinWidth_1024.HotelPaxResultSmallScreen>h4>span.anticon.anticon-down.lessThanOrEqual-desktopMinWidth_1024.DownOutlined_SmallPortrait {
  margin: 2px;
}
