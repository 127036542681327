.switcher {
  text-align: left;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.switcher h4 {
  color: rgb(25, 25, 94);
  font-size: 90%;
  font-weight: bold;
}

.switcher_icon_autocomplete_item {
  color: rgb(25, 25, 94);
  font-size: 90%;
  font-weight: bold;
}

.switcher_mobile h4 {
  color: rgb(25, 25, 94);
  /* ##### 22-11-08 Komarov */
  font-size: 100%;
  font-weight: bold;
}

.switcher__item {
  background-color: #cddef8;
  color: rgb(175, 170, 170);
  font-weight: bold;
  cursor: pointer;
  padding: 7px 5px 5px 7px;
  border-top: 1px solid #8bc0f0;
  border-left: 1px solid #8bc0f0;
  border-right: 1px solid #8bc0f0;
  border-bottom: none;
  /* ##### 23-06-27 Komarov */
  border-top-right-radius: 2vw;
  border-top-left-radius: 1vw;
  width: 35%;
}

.switcher__item.active {
  background-color: #96c6f0;
  color: #003057;
}

.switcher__item_tablet_portrait {
  background-color: #cddef8;
  color: rgb(175, 170, 170);
  font-weight: bold;
  cursor: pointer;
  padding: 7px 5px 5px 7px;
  border-top: 1px solid #8bc0f0;
  border-left: 1px solid #8bc0f0;
  border-right: 1px solid #8bc0f0;
  border-bottom: none;
  border-top-right-radius: 5vw;
  border-top-left-radius: 2vw;
  width: 15%;
  height: 100%;
}

.switcher__item_tablet_portrait.active {
  background-color: #96c6f0;
  color: #003057;
}

.switcher__item_mobile {
  background-color: #cddef8;
  color: rgb(175, 170, 170);
  font-weight: bold;
  cursor: pointer;
  padding: 7px 5px 5px 7px;
  border-top: 1px solid #8bc0f0;
  border-left: 1px solid #8bc0f0;
  border-right: 1px solid #8bc0f0;
  border-bottom: none;
  border-top-right-radius: 3vw;
  border-top-left-radius: 1.5vw;
  /* ##### 23-02-28 Komarov */
  width: 50%;
}

.switcher__item_mobile.active {
  background-color: #96c6f0;
  color: #003057;
}

/* ##### 22-10-26 Komarov */
.ant-picker-range.ant-picker-focused .ant-picker-active-bar {
  transform: translateY(-0.3rem);
}

/* ##### 24-05-21 Komarov: стили для показа и анимации условных обозначений точек в Autocomplete */
.hotels-cities-availability-hint.show {
  /* Конечная прозрачность: 1 (непрозрачный) */
  opacity: 1;
  /* Конечное смещение: 5% + 5em (справа от вкладки "Отели") */
  transform: translateX(calc(5% + 5em));
}

.hotels-cities-availability-hint {
  z-index: -1;
  position: absolute;
  /* Начальная прозрачность: 0 (прозрачный) */
  opacity: 0;
  /* Начальное смещение: за вкладкой "Отели" */
  transform: translateX(5%);
  /* Анимация прозрачности и смещения */
  transition:
    opacity 1s ease-in-out,
    transform 1s ease-in-out;
}
