.HotelDescriptionUl {
  margin-left: auto;
  margin-right: auto;
  margin-top: 5vh;
  /* ##### 24-09-23 Komarov */
  font-family: Tahoma, sans-serif;
}

.HotelDescriptionUlSmallScreen {
  margin-left: auto;
  margin-right: auto;
  margin-top: 5vh;
  /* ##### 24-09-23 Komarov */
  font-family: Tahoma, sans-serif;
}

.HotelDescriptionLi {
  display: grid;
  grid-template-columns: 33% 67%;
  /* ##### 23-07-05 Komarov */
  text-align: justify;
  border: 2px solid #d4e5f7;
  border-radius: 5px;
  background: #dce5fc;
  padding: 1vw 2vw;
  color: #001959;
  margin-bottom: 1vh;
}

.HotelDescriptionLiSmallScreen {
  display: flex;
  flex-direction: column-reverse;
  text-align: justify;
  border: 2px solid #d4e5f7;
  border-radius: 5px;
  background: #dce5fc;
  padding: 1vw 2vw;
  color: #001959;
  margin-bottom: 1vh;
}

.descriptionContent {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
}

.searchFront {
  /*the container must be positioned relative:*/
  position: relative;
  display: inline-block;
}


.searchrendering_Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.WrapperHotel_ItemContent {
  padding-right: 2vw;
  grid-column: 1 / 4;
  grid-row: 1 / 3;
}

.Hotel_ItemContent {
  padding: 0;
  display: grid;
  grid-template-columns: 30vw 20vw;
  /* ##### 24-10-18 Komarov */
  /* grid-template-rows: 30% 70%; */
  /* ##### 23-09-09 Komarov */
  margin-bottom: 0;
}

.Hotel_ItemContentSmallScreen {
  /* ##### 23-11-13 Komarov */
  display: flex;
  flex-direction: column;
}

.Li_HotelContent {
  list-style-type: none;
  font-size: 12px;
  text-align: justify;
  color: #001959;
  padding-left: 1vw;
}

.Li_HotelContentSmallScreen {
  grid-row: 1/4;
  grid-column: 2;
  /* ##### 23-11-13 Komarov */
  margin: initial;
}

.Li_HotelContent span {
  font-size: 12px;
  text-align: justify;
  color: #001959;
}

.HotelNameStarRating {
  display: flex;
  flex-direction: row;
}

.HotelNameStarRatingSmallScreen {
  display: flex;
  /* ##### 23-11-13 Komarov */
  flex-direction: row;
}

.HotelAddressSmallScreen {
  grid-row: 2;
  grid-column: 2;
  font-size: 12px;
}

.Li_Image {
  list-style-type: none;
  text-align: left;
}

.Li_ImageSmallScreen {
  list-style-type: none;
  grid-row: 1/3;
  grid-column: 1;
}

.div_ItemObj {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-end;
  max-width: 30vw;
}

.availablePeriods {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 1vw;
  padding-bottom: 4vw;
}

.minimumRate_details {
  font-size: 12px;
  font-weight: bold;
}

/*!!!!! BUTTONS !!!!!*/

.availableButtonHotel {
  padding: 0.5vw 1vw;
  /* ##### 23-11-13 Komarov */
  margin-bottom: 3vh;
  width: 55%;
  font-size: 13px;
  font-weight: bold;
  background-color: #5EB82F;
  color: white;
  border-radius: 0.5vw;
  position: relative;
  z-index: 50;
}

.availableButtonHotel:hover {
  cursor: pointer;
  background-color: rgb(8, 131, 14);
  color: rgb(250, 250, 141)
}

/* ##### 24-04-05 Komarov */
.onRequestButtonHotel {
  padding: 0.5vw 1vw;
  margin-bottom: 3vh;
  width: 55%;
  font-size: 13px;
  font-weight: bold;
  background-color: #b8612f;
  color: white;
  border-radius: 0.5vw;
  position: relative;
  z-index: 50;
}

/* ##### 24-04-05 Komarov */
.onRequestButtonHotel:hover {
  cursor: pointer;
  background-color: rgb(128, 61, 6);
  color: rgb(250, 250, 141)
}

.onrequestButton {
  padding: 0.5vw 1vw;
  width: 10vw;
  font-size: 13px;
  font-weight: bold;
  color: white;
  border-radius: 0.5vw;
  background-color: rgb(117, 111, 107);
}

.onrequestButton a {
  color: white;
  text-decoration: none;
}

.onrequestButton a:hover {
  color: white;
  text-decoration: underline;
}

.HotelAddress {
  list-style-type: none;
}

.RateLoading {
  /* ##### 23-12-29 Komarov */
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

}

.test:hover {
  cursor: pointer;
}

/* ##### 27-01-25 Komarov */
.PropertiesFound {
  color: #001959;
  background-color: rgb(255, 239, 131);
  /* border: 2px solid #001959; */
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-top: 2vh;
  padding-bottom: 2vh;
  /* ##### 24-08-14 Komarov */
  /* font-size: 25px; */
  font-size: 22px;
  max-width: 80%;
  font-style: Italic;
}

.SearchResults {
  margin-top: 2vw;
  color: #003057;
  font-family: Arial;
  /* ##### 24-08-14 Komarov */
  /* font-size: 30px; */
  font-size: 22px;
  font-weight: bold;
}

.hotel-id {
  font-size: 10px;
  color: grey;
  font-style: italic;
  grid-column: 1;
  grid-row: 2;
}