.footer {
  width: 100%;
  background-color: #003057;
  min-height: 25vh;
  padding-bottom: 3vw;
  /*##### 24-12-19 Komarov*/
  /*bottom: 0;*/
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.footer h2 {
  line-height: 1rem;
}

.footer ul a {
  /*##### 24-12-19 Komarov*/
  /*height: 1.5vw;*/
  padding-top: 0.3vw;
  padding-bottom: 0.3vw;
  color: white;
  padding-left: 0;
  /* ##### 24-08-14 Komarov */
  font-size: 16px;
  /* ##### 24-09-23 Komarov */
  font-family: Arial, sans-serif;
  position: relative;
  z-index: 2000;
}

/* ##### 24-07-22 Komarov */
.footer ul a[role="button"] {
  height: 1.5vw;
  padding-top: 0.3vw;
  padding-bottom: 0.3vw;
  color: white;
  padding-left: 0;
  /* ##### 24-08-14 Komarov */
  font-size: 16px;
  /* ##### 24-09-23 Komarov */
  font-family: Arial, sans-serif;
  position: relative;
  z-index: 2000;
}

.footer ul {
  list-style: none;
  padding-left: 0;
  margin-left: auto;
  margin-right: auto;
}

.footer ul a:hover {
  text-decoration: underline;
}

/* ##### 24-07-22 Komarov */
.footer ul a[role="button"]:hover {
  text-decoration: underline;
  color: white;
}

.footerLinks {
  margin-top: 2vw;
}

.footerLinks h3 {
  padding-bottom: 1.2vh;
}

.footer h3 {
  color: white;
  font-weight: bold;
  /* ##### 24-09-23 Komarov */
  font-family: Tahoma, sans-serif;
  /* ##### 24-08-14 Komarov */
  font-size: 17px;
}
