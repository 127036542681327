.largeScreenSearch {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
}

.mySearch {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 25%) 27% 10% 13%;
  justify-content: center;
  border-radius: 0 10px 10px 10px;
  border-right: 50px solid #96c6f0;
  border-left: 50px solid #96c6f0;
  border-top: 15px solid #96c6f0;
  border-bottom: 15px solid #96c6f0;
}

.mySearch .PromoCode {
  grid-column: 4;
  border-left: 0.5px solid grey;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: rgb(136, 6, 6);
  font-weight: bold;
}

.mySearch input::placeholder {
  /* ##### 24-09-23 Komarov */
  font-family: Tahoma, sans-serif;
  font-size: 14px;
}

.textInput,
.mySearch select {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  color: #001959;
  width: 40vw;
  padding: 1vw;
  border-bottom: none;
  border-top: none;
  border-left: none;
  border-right: 1px solid #001959;
}

.dropdownDatePicker {
  /* ##### 24-09-23 Komarov */
  font-family: Arial, sans-serif;
}

.mySearch button {
  background-color: #005AEB;
  color: white;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  width: 10vw;
  /* ##### 24-08-14 Komarov */
  height: 38px;
  border-radius: 0.5vw;
  font-weight: bold;
  margin: auto;
}

.mySearch button:hover {
  background-color: #001959;
  color: white;
  cursor: pointer;

}


.formInnerWrapper {
  border: 3px solid white;
  width: 70.5vw;
  border-radius: 0.5vw;

}

.formOuterWrapper {
  border-top: 1.5vw solid #96c6f0;
  border-bottom: 1.5vw solid #96c6f0;
  border-left: 5vw solid#96c6f0;
  border-right: 5vw solid #96c6f0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.3vw;
  border-radius: 0.5vw;
  width: 80vw;
}

.borderInnerWrapper2 {
  border: 2px solid white;
}


div.datePicker {
  /* ##### 24-09-23 Komarov */
  font-family: Tahoma, sans-serif;
  display: flex;
  align-items: center;
}

input::placeholder {
  color: rgb(75, 73, 73);
  font-size: 90%;
}

.ant-picker-input input::placeholder {
  color: rgb(75, 73, 73);
}

.MonthPicker .ant-picker-input {
  width: 20vw;
  padding-top: 1vw;
}