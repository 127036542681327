.LangSwitcher {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.LangSwitcher_item.active,
.LangSwitcher_item:hover {
  opacity: 0.8;
  background-color: rgb(38, 71, 141);
}

.LangSwitcher_item {
  cursor: pointer;
  border: 2px solid white;
  background-color: white;
  border-radius: 10px;
}
