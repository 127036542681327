.ChoiceBlockWrapper {
  display: grid;
  grid-template-columns: repeat(2, 20vw);
}

.Icons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: #102D69;
  /* ##### 24-09-23 Komarov */
  font-family: Arial, sans-serif;
  font-size: 17px;
}


.BookingDetails {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 45%;
  min-height: 60vh;
  border: 2px solid rgb(206, 207, 209);
  border-radius: 6px;
  margin-top: 2vw;
}

.BookingDetails h3 {
  text-align: center;
  color: #005AEB;
  font-size: 22px;
  font-weight: bold;
  /* ##### 24-09-23 Komarov */
  font-family: 'Arial Narrow', sans-serif;
}

.BookingChoice {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 1vw;
}

.ChoiceBlock {
  display: flex;
  flex-direction: column;
  padding-left: 3vw;
}


.ChoiceBlock h4 {
  /* ##### 24-09-23 Komarov */
  font-family: 'Arial Narrow', sans-serif;
  font-weight: bold;
  font-size: 90%;
  color: rgb(77, 75, 75);
}

.FrontHotelPaxChoiceWrapper {
  display: flex;
  flex-direction: row;
  /* ##### 24-09-23 Komarov */
  font-Family: Tahoma, sans-serif;
  font-size: 16px;
}


.FrontHotelPaxChoiceWrapper h4 {
  /* ##### 24-09-23 Komarov */
  font-family: Tahoma, sans-serif;
  font-size: 14px;
  color: rgb(75, 73, 73);
  cursor: pointer;
}

/* ##### 24-02-02 Komarov */
.FrontHotelPaxChoiceWrapper div {
  /* ##### 24-09-23 Komarov */
  font-family: Tahoma, sans-serif;
  font-size: 14px;
  color: rgb(75, 73, 73);
  cursor: pointer;
}

/* ##### 22-10-25 Komarov */
.FrontHotelPaxChoiceWrapper_SmallLandscape {
  display: flex;
  flex-direction: row;
  height: 25px;
  /* ##### 24-09-23 Komarov */
  font-Family: Tahoma, sans-serif;
  /* ##### 22-10-27 Komarov: выровнял расположение строк формы поиска на разных мобильных устройствах */
  font-size: 14px;
  transform: translateY(-121px);
  justify-content: space-around;
}

.FrontHotelPaxChoiceWrapper_SmallLandscape h4 {
  /* ##### 24-09-23 Komarov */
  font-family: Tahoma, sans-serif;
  font-size: 14px;
  color: rgb(75, 73, 73);
  cursor: pointer;
}

.FrontHotelPaxResult {
  /* border: 1px solid #d9d9d9; */
  border-radius: 2px;
  /* ##### 24-01-30 Komarov */
  background-color: white;
}

.FrontHotelPaxResultSmallScreen {
  border-radius: 2px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  /* ##### 24-09-23 Komarov */
  font-family: Tahoma, sans-serif;
}

/* ##### 22-11-08 Komarov */
.FrontHotelPaxResultSmallScreenOuterDiv {
  border-radius: 2px;
  width: 90%;
  background-color: white;
  display: flex;
  justify-content: center;
  /* ##### 23-03-30 Komarov */
  transform: translate3d(3vw, 0.4em, 0);
  font-family: Arial, Helvetica, sans-serif;
}

.DropdownButton {
  margin-top: 0.5vh;
  background-color: white;
  border: 2px solid rgb(177, 174, 174);
  border-radius: 5px;
  min-width: 200px;
}

.DropdownItem {
  font-weight: bold;
  display: flex;
  flex-direction: column;
  color: #102D69;
}

.DropdownItem:hover {
  background-color: #d7ebfc;
  text-decoration: none;
}

.DescriptionTourDetails {
  margin-left: 4vw;
}

.PopUpNotActive {
  display: none;
}

/* ##### 24-01-30 Komarov */
.PopUpActive-HotelsAutocompleteBlock {
  display: block;
  position: absolute;
  background-color: white;
  /* ##### 24-01-30 Komarov */
  z-index: 2001;
  /* ##### 24-01-30 Komarov */
  width: 22vw;
  margin-top: 2.3em;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 15px;
}

/* ##### 24-02-06 Komarov */
.PopUpActive-HotelsAutocompleteBlock-Mobile {
  display: block;
  background-color: white;
  z-index: 2001;
  width: 22vw;
  margin-top: 2.3em;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  padding: 15px;
}

.anticon {
  align-items: center;
  justify-content: center;
}

.PopUpButton {
  background: #009FE3;
  color: white;
  border: 1px solid grey;
  border-radius: 2px;
  font-size: 19px;
  /* ##### 23-11-15 Komarov */
  height: 50px;
  text-align: center;
}

.BookingChoiceInner {
  display: flex;
  flex-direction: column;
}

.DateSelection {
  font-size: 14px;
  color: blue;
  font-weight: bold;
}

.Minus {
  color: white;
  background-Color: #009FE3;
  border: 0.8vw solid #009FE3;
  border-radius: 5vw;
  font-size: 15px;
  font-weight: bolder;
  width: 40px;
  height: 40px
}

.Plus {
  color: white;
  background-Color: #009FE3;
  border: 0.8vw solid #009FE3;
  border-radius: 5vw;
  font-size: 15px;
  font-weight: bolder;
  width: 40px;
  height: 40px
}

.DownOutlined {
  color: #d9d9d9;
  font-size: 13px;
  padding-left: 5px;
}

.RadioWrapper {
  margin-top: 20px;
}

.RadioWrapper h5 {
  color: blue;
  font-size: 19px;
  /* ##### 24-09-23 Komarov */
  font-family: 'Arial Narrow', sans-serif;
  font-weight: bold;
  min-width: 35vw;
  margin-bottom: 15px;
}

.Radio {
  margin-top: 20px;
  min-width: 35vw;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.WrapperAddToBasket {
  min-width: 35vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.WrapperAddToBasket h4 {
  width: 30vw;
  height: 25px;
  text-align: left;
  margin-top: 25px;
  margin-bottom: 8px;
}

.calendar-row {
  display: flex;
  flex-wrap: nowrap;
  margin-right: -15px;
  margin-left: -15px;
  height: 2.5em !important;
}

.calendar-row-tablet {
  display: flex;
  flex-wrap: nowrap;
  margin-right: 10px;
  margin-left: 10px;
  height: 2.5em;
}

.calendar-col-lms {
  flex-basis: 0;
  flex-grow: 1;
  position: relative;
  column-width: 13.1vw;
}

.calendar-col-ms {
  flex-basis: 0;
  flex-grow: 1;
  position: relative;
  column-width: 13vw;
}

.calendar-col-mm {
  flex-basis: 0;
  flex-grow: 1;
  position: relative;
  column-width: 12.5vw;
}

.calendar-col-ml {
  flex-basis: 0;
  flex-grow: 1;
  position: relative;
  column-width: 12.5vw;
}

.calendar-col-t {
  flex-basis: 0;
  flex-grow: 1;
  position: relative;
  column-width: 12.5vw;
}

.calendar-weekdays-col-t {
  flex-basis: 0;
  flex-grow: 1;
  position: relative;
  column-width: 6.37vw;
}

.calendar-not-this-month {
  color: #9e9e9e !important;
}

.calendar-start-date {
  background-color: #005aeb !important;
  color: white !important;
  border-bottom-left-radius: 1.2em;
  border-top-left-radius: 1.2em;
}

.calendar-end-date {
  background-color: #005aeb !important;
  color: white !important;
  border-bottom-right-radius: 1.2em;
  border-top-right-radius: 1.2em;
}

.calendar-middle-date {
  background-color: #b7cbec !important;
}

.calendar-disabled-date {
  color: #9e9e9e !important;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar-ok-button-active {
  background-color: #005AEB;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 1rem;
  /* ##### 24-05-16 Komarov */
  padding: 6.75px 3vw;
  font-weight: bold;
}

.calendar-ok-button-inactive {
  background-color: #9e9e9e;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 1rem;
  /* ##### 24-05-16 Komarov */
  padding: 6.75px 3vw;
  font-weight: bold;
}

/* ##### 24-02-02 Komarov */
#HotelsAutocomplete>div:nth-child(2)>input::placeholder {
  /* ##### 24-09-23 Komarov */
  font-Family: Tahoma, sans-serif;
  font-size: 14px;
  color: rgb(75, 73, 73);
}

#RangePicker input::placeholder {
  /* ##### 24-09-23 Komarov */
  font-Family: Tahoma, sans-serif;
  font-size: 14px;
  color: rgb(75, 73, 73);
}

#RangePicker input::placeholder {
  /* ##### 24-09-23 Komarov */
  font-Family: Tahoma, sans-serif;
  font-size: 14px;
  color: rgb(75, 73, 73);
}