.WrapperTermsConditions {
  display: grid;
  grid-template-columns: 20% 80%;
}

.TermsConditions {
  /* ##### 24-09-23 Komarov */
  font-family: 'Arial Narrow', sans-serif;
  font-weight: bold;
  color: blue;
  font-size: 17px;
}

.TermsConditions:hover {
  /* ##### 24-09-23 Komarov */
  font-family: 'Arial Narrow', sans-serif;
  font-weight: bold;
  text-decoration: underline;
  font-size: 17px;
  cursor: pointer;
}

/* ##### 23-07-11 Komarov */
#root>header>div.FormWrapper>div>form.greaterThan-desktopMinWidth_1024.myForm>div.WrapperTermsConditions>label>span {
  border: #009fe3;
  border-style: ridge;
}