.LoadingOutlinedIcon {
  border: none;
  rotate: 20deg;
  font-size: 40px;
}

.Loading {
  height: 270px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.LoadingText {
  color: rgb(7, 7, 85);
  font-size: 17px;
  /* ##### 24-09-23 Komarov */
  font-family: Arial, sans-serif;
  margin: auto;
  top: 40%;
}
