.HotelFooterPages {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1vh;
}

.HotelFooterPagesSmallScreen {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1vh;
  /* ##### 23-09-08 Komarov */
  display: flex;
  justify-content: center;
  /* ##### 23-11-09 Komarov */
  flex-direction: column;
}

.HotelFooterPages h2 {
  color: white;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  /* ##### 24-09-23 Komarov */
  font-family: Arial, sans-serif;
  background-color: #6194c0;
  padding: 2vw;
  border-radius: 1vw;
}

.HotelFooterPagesSmallScreen h2 {
  color: white;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  /* ##### 24-09-23 Komarov */
  font-family: Arial, sans-serif;
  background-color: #6194c0;
  padding: 2vw;
  border-radius: 1vw;
}

.HotelContentWrapper {
  background: #ddeaf5;
  border-radius: 5px;
  /* ##### 23-09-09 Komarov */
  padding: 2vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 3px solid white;
}

.HotelContentWrapperSmallScreen {
  background: #ddeaf5;
  border-radius: 5px;
  padding-top: 2vh;
  padding-right: 3vh;
  padding-left: 3vh;
}

/* ##### 23-11-13 Komarov */
.HotelContentWrapperMediumScreen {
  background: #ddeaf5;
  border-radius: 5px;
  padding-top: 2vh;
  padding-right: 3vh;
  padding-left: 3vh;
}

.HotelContentWrapper img {
  margin-bottom: 2vh;
}

.HotelContentWrapper button {
  height: 100%;
  background: #009FE3;
  color: white;
  border: 1px solid grey;
  border-radius: 2px;
  font-weight: bold;
  font-size: 90%;
  width: 25%;
  text-align: center;
  padding-bottom: 2vh;
  padding-top: 2vh;
  /* ##### 23-08-08 Komarov */
  margin-bottom: 8px;
}

.HotelContentWrapper button:hover {
  cursor: pointer;
  background: #024461;
  color: white;
}

.HotelContentWrapperSmallScreen h3 {
  font-size: 15px;
}