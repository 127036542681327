.TourDetailsWrapper {
  display: flex;
  flex-direction: column;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.TourDetailsWrapper h2 {
  color: #102d69;
  font-family: "Arial", serif;
  font-size: 22px;
  font-weight: bold;
}

.TourChoiceBlockWrapper {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  margin-top: 2vh;
}

.Icons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: #102d69;
  font-family: "Arial", serif;
  font-size: 17px;
}

.TourDetailsInner {
  display: flex;
  flex-direction: row;
  /* ##### 24-03-21 Komarov */
  justify-content: space-between;
  width: 100%;
}

.TourDetailsInnerSmallScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.GalleryTourDetails {
  margin-right: 3vw;
}

.TourBookingDetails {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 60vh;
  border: 2px solid rgb(206, 207, 209);
  border-radius: 6px;
  margin-top: 2vw;
}

.TourBookingDetails h3 {
  text-align: center;
  color: #005aeb;
  font-size: 22px;
  font-weight: bold;
  font-family: "Arial Narrow", serif;
}

.TourBookingChoice {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-template-rows: auto;
  margin-left: 3vw;
}

.TourChoiceBlock {
  display: flex;
  flex-direction: column;
}

.TourRateLoading {
  width: 285px;
  height: 250px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.TourChoiceBlock h4 {
  font-family: "Arial Narrow", serif;
  font-weight: bold;
  font-size: 17px;
  color: rgb(77, 75, 75);
}

.TourPaxChoiceWrapper {
  width: 50%;
}

.TourPaxChoiceWrapper h4 {
  font-family: "Arial Narrow", serif;
  font-weight: bold;
  font-size: 17px;
  color: rgb(77, 75, 75);
}

.TourInclusionsBlock {
  display: flex;
  flex-direction: column;
}

.TourInclusionsBlock h4 {
  font-family: "Arial Narrow", serif;
  font-weight: bold;
  font-size: 17px;
  color: rgb(77, 75, 75);
}

.TourBookingChoice h4 {
  font-family: "Arial Narrow", serif;
  font-weight: bold;
  font-size: 17px;
  color: rgb(77, 75, 75);
}

.PaxChoice {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.PaxChoice h4 {
  font-family: "Arial Narrow", serif;
  font-weight: bold;
  font-size: 17px;
  color: rgb(77, 75, 75);
}

.PaxResult {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  height: 32px;
  width: 90%;
  padding: 4px 11px;
  background-color: white;
}

.PaxResult h4 {
  color: rgb(16, 45, 105);
  font-family: "Arial Narrow", serif;
  font-size: 16px;
  cursor: pointer;
}

.DropdownButton {
  margin-top: 0.5vh;
  background-color: white;
  border: 2px solid rgb(177, 174, 174);
  border-radius: 5px;
  min-width: 200px;
}

.DropdownItem {
  font-weight: bold;
  display: flex;
  flex-direction: column;
  color: #102d69;
}

.DropdownItem:hover {
  background-color: #d7ebfc;
  text-decoration: none;
}

.DescriptionTourDetails {
  margin-left: 4vw;
}

.TourPopUpNotActive {
  display: none;
}

.TourPopUpActive {
  display: block;
  position: absolute;
  background-color: white;
  z-index: 1000;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 15px;
}

.TourPopUpButton {
  background: #009fe3;
  color: white;
  border: 1px solid grey;
  border-radius: 2px;
  font-size: 19px;
  width: 19vw;
  height: 50px;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 6px;
}

.BookingChoiceInner {
  display: flex;
  flex-direction: column;
}

.DateSelection {
  font-size: 14px;
  color: blue;
  font-weight: bold;
}

.Minus {
  color: white;
  background-color: #009fe3;
  border: 0.8vw solid #009fe3;
  border-radius: 5vw;
  font-size: 15px;
  font-weight: bolder;
  width: 40px;
  height: 40px;
}

.Plus {
  color: white;
  background-color: #009fe3;
  border: 0.8vw solid #009fe3;
  border-radius: 5vw;
  font-size: 15px;
  font-weight: bolder;
  width: 40px;
  height: 40px;
}

.DownOutlined {
  color: #d9d9d9;
  font-size: 13px;
  padding-left: 5px;
}

.RadioWrapper {
  margin-top: 20px;
}

.RadioWrapper h5 {
  color: blue;
  font-size: 19px;
  font-family: "Arial Narrow", serif;
  font-weight: bold;
  min-width: 35vw;
  margin-bottom: 15px;
}

.Radio {
  margin-top: 20px;
  min-width: 35vw;
  display: flex;
  flex-direction: row;
  /* ##### 24-03-21 Komarov */
  justify-content: space-between;
}

.TourWrapperAddToBasket {
  display: grid;
  grid-template-columns: 60% 40%;
  padding-top: 3vh;
  margin-top: 0;
  column-gap: 4vw;
}

.WrapperAddToBasket h4 {
  width: 30vw;
  height: 25px;
  text-align: left;
  margin-top: 25px;
  margin-bottom: 8px;
}

.AddToCartTour {
  background: #009fe3;
  color: white;
  border: 1px solid grey;
  border-radius: 2px;
  font-weight: bold;
  font-size: 19px;
  width: 45%;
  height: 6vh;
  text-align: center;
  padding-bottom: 5px;
  margin-top: 25px;
  margin-bottom: 8px;
  position: relative;
  z-index: 50;
}

.AddToCartTour:hover {
  cursor: pointer;
}
