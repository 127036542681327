.FormWrapper {
  margin: 3vh 10vw 5vh 10vw;
  position: relative;
}

.FormWrapper h2 {
  padding: 1vw;
  border: 2px solid rgb(206, 207, 209);
  border-radius: 0.5vw;
  /* ##### 24-09-23 Komarov */
  font-family: Arial, sans-serif;
  color: blue;
  font-size: 22px;
  font-weight: bold;
  background-color: #d7ebfc;
}

.HeadPolicies {
  padding: 1vw;
  border: 2px solid rgb(206, 207, 209);
  border-radius: 0.5vw;
  /* ##### 24-09-23 Komarov */
  font-family: Arial, sans-serif;
  color: #102d69;
  font-size: 17px;
  font-weight: bold;
  background-color: #ecf3fa;
}

.RegWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.RegWrapperSmallScreen {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-evenly;
}

.myForm {
  display: block;
  /* ##### 24-04-16 Komarov */
  padding: 0 1vw;
  border: 2px solid rgb(206, 207, 209);
  border-radius: 0.5vw;
  flex: 1;
  margin-right: 1vw;
}

.myFormSmallScreen {
  display: block;
  padding: 1vw;
  border: 2px solid rgb(206, 207, 209);
  border-radius: 0.5vw;
  flex: 1;
  margin-right: 1vw;
  width: 100%;
}

.InputBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* ##### 24-04-16 Komarov */
  margin-top: 1vw;
  border: 1px solid rgb(206, 207, 209);
  background-color: #f4f6f8;
  /* ##### 24-04-17 Komarov */
  padding: 0.5rem;
  border-radius: 5px;
}

/* ##### 24-04-17 Komarov */
.ReadOnlyBlock {
  margin-top: 1vw;
  border: 1px solid rgb(206, 207, 209);
  background-color: #f4f6f8;
  padding: 0.5rem;
  border-radius: 5px;
}

/* ##### 24-04-29 Komarov */
.ReadOnlyBlock.TermsConditions {
  display: flex;
  justify-content: space-evenly;
  margin-top: 1vw;
  border: 1px solid rgb(206, 207, 209);
  background-color: #f4f6f8;
  padding: 0.5rem;
  border-radius: 5px;
}

/* ##### 24-04-25 Komarov */
.ReadOnlyElement {
  display: flex;
  justify-content: space-between;
}

.RadioForm {
  min-width: 20vw;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

/* ##### 24-04-17 Komarov */
.FormLabelForReadOnlyDiv {
  /* ##### 24-09-23 Komarov */
  font-family: "Arial Narrow", sans-serif;
  font-weight: bold;
  font-size: 17px;
  color: rgb(77, 75, 75);
  margin-bottom: 0;
}

.FormLabel {
  /* ##### 24-09-23 Komarov */
  font-family: "Arial Narrow", sans-serif;
  font-weight: bold;
  font-size: 17px;
  color: rgb(77, 75, 75);
}

/* ##### 24-04-18 Komarov */
form.myForm .ant-checkbox-inner {
  border: 1px solid blue;
}

/* ##### 24-04-26 Komarov */
div.ReadOnlyElement input {
  width: 57%;
  background-color: #f4f6f8;
  border: none;
}
