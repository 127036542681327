.CartNotPickedImage {
  /* ##### 23-04-17 Komarov */
  object-fit: cover;
  padding: 5px;
  width: 100%;
  height: 11vh;
  border-radius: 0.7vw;
  cursor: pointer;
}

.CartPickedImage,
.CartNotPickedImage:hover {
  /* ##### 23-04-17 Komarov */
  object-fit: cover;
  padding: 5px;
  width: 100%;
  height: 11vh;
  border-radius: 0.7vw;
  cursor: pointer;
  opacity: 0.7;
  background-color: rgb(17, 17, 114);

}

/* ##### 24-06-26 Komarov */
.galleryPage {
  flex: 1 1 25%;
}