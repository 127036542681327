.NotPickedImage {
  padding: 5px;
  width: 100%;
  height: 11vh;
  border-radius: 0.7vw;
  cursor: pointer;
  /* ##### 23-11-09 Komarov */
  object-fit: cover;
}

.PickedImage,
.NotPickedImage:hover {
  padding: 5px;
  width: 100%;
  height: 11vh;
  border-radius: 0.7vw;
  cursor: pointer;
  opacity: 0.7;
  /* ##### 23-11-09 Komarov */
  object-fit: cover;
}
