/*##### 24-12-19 Komarov*/
/*.supplierWrapper {
  width: 95%;
  border: 2px solid rgb(99, 120, 189);
  border-radius: 10px;
  padding: 2vw;
  margin-left: auto;
  margin-right: auto;
}*/

.purecontentPage .supplierWrapper h3 div {
  border-radius: 0.5vw;
  /* ##### 24-09-23 Komarov */
  font-family: Arial, sans-serif;
  color: rgb(17, 17, 139);
  font-size: 15px;
  font-weight: bold;
  background-color: #d7ebfc;
  text-align: justify;
}

.purecontentPage .blocks div,
.purecontentPage .blocks_2 div,
.purecontentPage .blocks_4 div,
.purecontentPage .blocks_5 div,
.purecontentPage .form-block-lf div {
  margin-top: 0.5vh;
  /* ##### 23-09-06 Komarov */
  margin-bottom: 0.5vh;
  padding: 0;
  min-height: 2vh;
}

/*##### 24-12-19 Komarov*/
/*.purecontentPage .blocks {
  border: 1px solid lightblue;
  border-radius: 10px;
  background: rgb(248, 238, 238);
  margin-top: 1vh;
  display: grid;
  align-items: center;
  grid-template-columns: 40% 30% 30%;
}*/

/*.purecontentPage .blocks_2 {
  border: 1px solid lightblue;
  border-radius: 10px;
  background: rgb(248, 238, 238);
  margin-top: 1vh;
  display: grid;
  align-items: center;
  grid-template-columns: 30% 20% 25% 25%;
}*/

/*.purecontentPage .blocks_3 {
  border: 1px solid lightblue;
  border-radius: 10px;
  background: rgb(248, 238, 238);
  margin-top: 1vh;
  display: grid;
  align-items: center;
  grid-template-columns: 15% 30% 15% 30%;
}*/

/*.purecontentPage .blocks_4 {
  border: 1px solid lightblue;
  border-radius: 10px;
  background: rgb(248, 238, 238);
  margin-top: 1vh;
  display: grid;
  align-items: center;
  grid-template-columns: 30% 20% 35% 15%;
}*/

/*##### 24-12-19 Komarov*/
/*.purecontentPage .blocks_5 {
  border: 1px solid lightblue;
  border-radius: 10px;
  background: rgb(248, 238, 238);
  margin-top: 1vh;
  display: grid;
  align-items: center;
  grid-template-columns: 15% 19% 15% 18% 15% 18%;
}*/

/*.purecontentPage .blocks span,
.purecontentPage .blocks_2 span,
.purecontentPage .blocks_3 span,
.purecontentPage .blocks_4 span,
.purecontentPage .blocks_5 span {
  font-weight: bold;
  color: rgb(13, 3, 70);
  font-size: 14px;
  margin-left: 0.8vw;
  margin-right: 0.8vw;
}*/

/*##### 24-12-19 Komarov*/
/*.SupplierSubmitButton {
  background: #009FE3;
  color: white;
  border: 1px solid grey;
  border-radius: 2px;
  font-weight: bold;
  font-size: 90%;
  width: 25%;
  text-align: center;
  padding-bottom: 2vh;
  padding-top: 2vh;
  !* ##### 23-09-06 Komarov *!
  margin-top: 2vh;
  margin-bottom: 8px;
}*/

/*.SupplierSubmitButton:hover {
  background: darkblue;
}*/

/*
.SupplierMonth div.ant-picker-input input::placeholder {
  font-size: 13px;
  color: rgb(13, 3, 70);
  font-weight: bold;
}*/
