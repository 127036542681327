/* ##### 22-11-02 Komarov: скачал CSS и шрифты от Google и расположил их локально в проекте, чтобы не зависеть в этом от подключения к интернет */
@import url('ForAboutUsFonts.css');

.WrapperAboutUs {
  display: flex;
  flex-direction: column;
}

* {
  margin: 0;
  padding: 0;
}


.container {
  min-width: 100%;
  height: 30%;
  background: #065ca3;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1vw;
  border-radius: 10px;
}

.box {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.title {
  position: relative;
  display: flex;
  align-items: center;
  height: 60px;
}

.container .title .block {
  height: inherit;
  background: #ffb510;
  position: absolute;
  /* ##### 23-08-29 Komarov */
  display: flex;
  border-radius: 10px;
}

h1 {
  /* ##### 24-09-23 Komarov */
  font-family: Poppins, sans-serif;
  color: #fff;
  font-size: 32px;
  /* ##### 23-08-29 Komarov */
  display: flex;
  align-items: baseline;
  position: relative;
}

h1 span {
  /* ##### 23-08-29 Komarov */
  width: 5px;
  height: 5px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #ffb510;
  /* ##### 23-08-29 Komarov */
  margin-left: 5px;
  margin-top: -10px;
  position: absolute;
  /* ##### 23-08-29 Komarov */
  bottom: 11px;
  right: -7px;
}


.role {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.role .block {
  width: 0;
  line-height: 12px;
  background: #8d98f3;
  position: absolute;
  /* ##### 23-08-29 Komarov */
  display: flex;
  border-radius: 10px;
}

.role p {
  /* ##### 23-08-29 Komarov */
  font-weight: 400;
  /* ##### 24-09-23 Komarov */
  font-family: Lato, sans-serif;
  color: #ffffff;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 5px;
}

.desc {
  background: #f7eea9;
  position: relative;
  /* ##### 23-08-29 Komarov */
  display: flex;
  justify-content: center;
  align-items: center;
  /* ##### 23-08-29 Komarov */
  margin-bottom: 2vh;
  /* ##### 23-08-29 Komarov */
  padding: 2vh 3vw;
  border-radius: 10px;
}

@media not all and (max-width:768.2px) {
  .Advantages {
    display: grid;
    grid-template-columns: repeat(5, 20%);
    align-items: center;
    background-color: #003057;
    padding: 0 10px;
    min-height: 20vh;
    border-radius: 10px;
    margin-bottom: 2vh;

    position: relative;
    /* ##### 23-08-29 Komarov */
  }
}

@media not all and (min-width:769px) {
  .Advantages {
    background-color: #003057;
    padding: 0 10px;
    min-height: 20vh;
    border-radius: 10px;
    margin-bottom: 2vh;

    position: relative;
    /* ##### 23-08-29 Komarov */
  }
}

/* ##### 23-07-04 Komarov - end */

.Wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5vw 1vw 0.5vw 1vw;
  color: white;
}

.Wrap div {
  margin-left: auto;
  margin-right: auto;
}

.Advantages div {
  display: flex;
  justify-content: center;
  align-self: flex-start;
}

.purecontentPage .Advantages div {
  color: white;
  min-height: 0;
  text-align: center;
}

.purecontentPage .Advantages div.Numbers {
  font-size: 25px;
  font-weight: bold;
}

.MainText {
  position: relative;
}