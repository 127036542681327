.HotelDetailsWrapper {
  margin: 3vh 6vw 5vh 6vw;
}

.HotelDetailsWrapper h2 {
  color: #102D69;
  font-family: "Arial", serif;
  font-size: 27px;
  font-weight: bold;
  margin-right: 2vw;
}

.SearchDetails {
  /* ##### 23-05-05 Komarov */
  display: grid;
  grid-template-columns: 20% 20% 45% 15%;
  padding: 1vw;
  border: 2px solid rgb(206, 207, 209);
  border-radius: 0.5vw;
  background-color: rgb(245, 233, 159);
  text-align: center;
  margin-top: 2vh;
  margin-bottom: 5vh;
}

.SearchDetailsSmallScreen {
  /* ##### 23-05-05 Komarov */
  display: flex;
  flex-direction: column;
  padding: 1vw;
  border: 2px solid rgb(206, 207, 209);
  border-radius: 0.5vw;
  background-color: rgb(245, 233, 159);
  text-align: center;
  margin-top: 2vh;
  margin-bottom: 5vh;
}

/* ##### 24-04-05 Komarov */
.SearchDetails h4 {
  margin: auto;
}

.SearchDetails h4 {
  font-family: Arial, serif;
  color: #102D69;
  font-size: 17px;
  display: flex;
  flex-direction: column;
}

.SearchDetailsSmallScreen h4 {
  font-family: Arial, serif;
  color: #102D69;
  font-size: 17px;
  /* ##### 23-11-15 Komarov */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SearchDetails span {
  font-family: Arial, serif;
  color: blue;
  font-size: 17px;
  font-weight: bold;
}

.SearchDetailsSmallScreen span {
  font-family: Arial, serif;
  color: blue;
  font-size: 17px;
  font-weight: bold;
}

.ContentDetails {
  display: grid;
  grid-template-columns: 70% 30%;
}

.ContentDetailsSmallScreen {
  display: flex;
  flex-direction: column;
}

.availableButton {
  padding: 0.5vw 1vw;
  font-size: 17px;
  font-family: Arial, serif;
  font-weight: bold;
  background-color: #2f81b8;
  color: white;
  border-radius: 0.5vw;
}

.availableButton:hover {
  cursor: pointer;
  background-color: #085486;
  color: rgb(250, 250, 141)
}

.availableButtonSmallScreen {
  padding: 2vw 1vw;
  font-size: 17px;
  font-family: Arial, serif;
  font-weight: bold;
  background-color: #2f81b8;
  color: white;
  border-radius: 0.5vw;
  grid-column: 1/-1;
  max-width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.availableButtonSmallScreen:hover {
  cursor: pointer;
  background-color: #085486;
  color: rgb(250, 250, 141)
}

input::placeholder {
  color: rgb(75, 73, 73);
  font-size: 15px;
  font-family: 'Tahoma', serif;
  text-align: center;
}

.ContentBlockHotels {
  grid-column: 2;
  grid-row: 2;
  overflow: scroll;
  padding: 10px;
}

.ContentBlockHotels div,
.ContentBlockHotels p,
.ContentBlockHotels ul {
  color: #102D69;
  font-size: 14px;
  text-align: justify;
  font-family: 'Arial', serif;
}

.ContentBlockHotels li {
  margin-left: 25px;
}

.ContentBlockHotels li {
  list-style-type: disc;
  padding-left: 5px;
}

.ContentBlockHotels h4 {
  color: #102D69;
  font-size: 16px;
  text-align: justify;
  font-weight: bold;
  font-family: 'Arial', serif;
}