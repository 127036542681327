.galleryNotActive {
  display: none;
}

.galleryActive {
  display: block;
}

.purecontentPage {
  display: flex;
  flex-direction: column;
  color: rgb(3, 3, 63);
  justify-content: center;
  text-align: justify;
  margin-left: 6vw;
  margin-right: 6vw;
  /* ##### 23-08-29 Komarov */
  margin-top: 2vh;
  margin-bottom: 6vh;
}

/* ##### 24-06-17 Komarov */
.purecontentPage img {
  max-width: 100%;
  border-radius: 2px;
}

/* ##### 24-06-17 Komarov */
.svgContainer.yearHeader img {
  max-width: 100%;
  /* ##### 24-06-14 Komarov */
  height: 20vw;
  max-height: 126px;
  border-radius: 2px;
}

.purecontentPage div {
  color: rgb(95, 94, 94);
  font-size: 16px;
  text-align: justify;
  /* ##### 24-09-23 Komarov */
  font-family: Arial, sans-serif;
  /* ##### 23-10-24 Komarov */
  margin-bottom: 0.5rem
}

.purecontentPage p span {
  color: rgb(95, 94, 94);
  font-size: 16px;
  text-align: justify;
  /* ##### 24-09-23 Komarov */
  font-family: Arial, sans-serif;
  line-height: 22px;
}

.purecontentPage div span {
  color: rgb(95, 94, 94);
  font-size: 16px;
  text-align: justify;
  /* ##### 24-09-23 Komarov */
  font-family: Arial, sans-serif;
  line-height: 22px;
}

.purecontentPage h2 {
  color: white;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  /* ##### 24-09-23 Komarov */
  font-family: Arial, sans-serif;
  background-color: #6194c0;
  padding: 2vw;
  border-radius: 1vw;
}

.purecontentPage h3 {
  color: #102D69;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  /* ##### 24-09-23 Komarov */
  font-family: Arial, sans-serif;
  /* ##### 23-09-06 Komarov */
  padding-top: 2vh;
  padding-bottom: 2vh;
}

.purecontentPage h4 {
  color: royalblue;
  font-size: 18px;
  font-weight: bold;
  font-style: italic;
  /* ##### 24-09-23 Komarov */
  font-family: Arial, sans-serif;
  margin-bottom: 0.4vh;
  padding-top: 2vh;
  padding-bottom: 2vh;
}

.purecontentPage .inner {
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  /* ##### 24-09-23 Komarov */
  font-family: Arial, sans-serif;
  background-color: #6194c0;
  padding: 2vw;
  border-radius: 1vw;
  cursor: pointer;
}

.purecontentPage .innerClicked {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  /* ##### 24-09-23 Komarov */
  font-family: Arial, sans-serif;
  background-color: #1d3574;
  padding: 2vw;
  border-radius: 1vw;
  cursor: pointer;
}

.purecontentPage .inner a {
  text-decoration: none;
  color: white;
}

/* ##### 24-06-19 Komarov */
.purecontentPage .inner {
  text-decoration: none;
  color: white;
}

.purecontentPage .inner:hover {
  background-color: #1d3574;
}

.purecontentPage .innerClicked a {
  text-decoration: none;
  color: white;
}

/* ##### 24-06-19 Komarov */
.purecontentPage .innerClicked {
  text-decoration: none;
  color: white;
}