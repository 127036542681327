.totopbutton {
  display: block;
  position: fixed;
  padding: 13px;
  border: none;
  border-radius: 13px;
  right: 40px;
  bottom: 140px;
  background: rgb(13, 108, 192);
  cursor: pointer;
  font-weight: bold;
  font-size: 30px;
  color: white;
}

/* ##### 22-11-08 Komarov */
.totopbuttonMobile {
  display: block;
  position: fixed;
  padding: 13px;
  border: none;
  border-radius: 13px;
  right: 0;
  bottom: 0;
  background: rgb(13, 108, 192);
  cursor: pointer;
  font-weight: bold;
  color: white;
  /* ##### 24-05-16 Komarov */
  z-index: 100;
}

.notvisible {
  display: none;
}