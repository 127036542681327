.NonActivePmnt {
  display: none;
}

.ActivePmnt {
  display: block;
  color: blue;
  font-size: 20px;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
}

.NonActiveSubmit {
  /* ##### 24-04-24 Komarov */
  background: #96c6f0;
  color: white;
  border: 1px solid grey;
  border-radius: 2px;
  font-weight: bold;
  font-size: 90%;
  /* ##### 24-04-23 Komarov */
  text-align: center;
  padding: 2vh 1.3vh;
  margin-top: 25px;
  margin-bottom: 8px;
  /* ##### 24-04-24 Komarov */
  cursor: default !important;
}

.SubmitButton {
  background: #009fe3;
  color: white;
  border: 1px solid grey;
  border-radius: 2px;
  font-weight: bold;
  font-size: 90%;
  /* ##### 24-04-23 Komarov */
  text-align: center;
  padding: 2vh 1.3vh;
  margin-top: 25px;
  margin-bottom: 8px;
}

.SubmitButton:hover {
  background: darkblue;
}

/* ##### 24-04-19 Komarov */
.BackToRoomsButton {
  background: #009fe3;
  color: white;
  border: 1px solid grey;
  border-radius: 2px;
  font-weight: bold;
  font-size: 90%;
  text-align: center;
  padding: 2vh 1.3vh;
  margin-top: 25px;
  margin-bottom: 8px;
}

.BackToRoomsButton:hover {
  background: darkblue;
}
