.CartDetailsSummary {
  padding: 1vw;
  border: 2px solid rgb(206, 207, 209);
  border-radius: 0.5vw;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.CartDetailsSmallScreenSummary {
  padding: 1vw;
  border: 2px solid rgb(206, 207, 209);
  border-radius: 0.5vw;
  display: inline-block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.CartDetailsSummary h4 {
  color: rgb(77, 75, 75);
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.CartDetailsSmallScreenSummary h4 {
  color: rgb(77, 75, 75);
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.VoucherText {
  display: flex;
  justify-content: center;
}

.VoucherText h4 {
  color: rgb(77, 75, 75);
  font-size: 15px;
  font-weight: bold;
  text-align: center;
}
