.CartDetails {
  padding: 1vw;
  border: 2px solid rgb(206, 207, 209);
  border-radius: 0.5vw;
  display: inline-block;
  /* ##### 24-04-17 Komarov */
  width: 50%;
}

.CartDetailsSmallScreen {
  padding: 1vw;
  border: 2px solid rgb(206, 207, 209);
  border-radius: 0.5vw;
  display: inline-block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.CartDetails h4 {
  color: rgb(77, 75, 75);
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.CartDetailsSmallScreen h4 {
  color: rgb(77, 75, 75);
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.MainRateDetails {
  display: grid;
  width: 100%;
  grid-template-columns: 40% 60%;
}

.MainRateDetails h5 {
  color: rgb(77, 75, 75);
  font-size: 16px;
}

.TaxDetails {
  font-size: 14px;
}
