/* ##### 23-04-08 Komarov: добавил постоянный скролбар, чтобы страница не дёргалась при загрузке */
body {
  overflow-y: scroll;
}

/* ALL CSS FOR VERY TOP MENU */
.topMenu {
  display: flex;
  flex-direction: row;
  /* ##### 23-04-28 Komarov */
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  background-color: #003057;
  height: 8vh;
  padding: 0.3vw;
  text-align: center;
  /*##### 24-12-19 Komarov*/
  /*top: 0;
  left: 0;*/
  font-weight: bold;
}

/* ##### 23-03-23 Komarov */
.topMenuMobilePortrait {
  display: flex;
  flex-direction: row;
  /* ##### 23-03-24 Komarov */
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #003057;
  /* ##### 24-09-23 Komarov */
  padding: 0.3vw;
  text-align: center;
  top: 0;
  left: 0;
  font-weight: bold;
  /* ##### 23-03-30 Komarov */
  height: 2.7em;
}

/* $$$$$ 22-09-15 Komarov */
.topMenuMobileLandscape {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  background-color: #003057;
  /* ##### 24-09-23 Komarov */
  padding: 0.3vw;
  text-align: center;
  top: 0;
  left: 0;
  font-weight: bold;
  /* ##### 23-03-30 Komarov */
  height: 3.8em;
}

/* ##### 23-03-30 Komarov */
.topMenuTabletPortrait {
  display: flex;
  flex-direction: row;
  /* ##### 23-11-01 Komarov */
  justify-content: space-around;
  align-items: center;
  width: 100%;
  background-color: #003057;
  text-align: center;
  font-weight: bold;
  /* ##### 23-03-30 Komarov */
  height: 3.8em;
}

/* ##### 23-03-24 Komarov */
.topMenuTabletLandscape {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  background-color: #003057;
  text-align: center;
  font-weight: bold;
  /* ##### 23-03-30 Komarov */
  height: 3.8em;
}

.WhiteLabelTopMenu {
  background-position: center;
  background-size: cover;
  border-bottom: 10px solid rgb(38, 71, 141);
  width: 100%;
  top: 0;
  left: 0;
  height: 55vh;
  margin-bottom: 8vh;
}

/* FOR WHITELABLES COMPANIES LIKE ROTARY WHEN I DO NOT WANT TO SHOW OUR MENU */
.NotVisibleMenu {
  display: none;
}

.topMenu_right {
  display: flex;
  align-items: center;
  /* ##### 23-04-28 Komarov */
  text-align: center;
}

.topMenu_right_mobile {
  display: flex;
  flex: 3;
  align-items: center;
  /* ##### 24-05-31 Komarov */
  justify-content: space-evenly;
  /* ##### 23-11-01 Komarov */
  width: 50vw;
}

.topMenu a {
  /* ##### 24-09-23 Komarov */
  font-family: Arial, sans-serif;
  color: white;
  text-decoration: none;
  padding: 1vw;
}

.topMenu a:hover {
  color: yellow;
  list-style: underline;
}

/* ##### 24-08-07 Komarov */
.topMenu a[role="button"].DropDownMenu {
  /* ##### 24-09-23 Komarov */
  font-family: Arial, sans-serif;
  color: white;
  text-decoration: none;
  padding: 1vw;
}

.topMenu a[role="button"].DropDownMenu:hover {
  color: yellow;
  text-decoration: underline;
}

div[data-component="DropDownMenuItems"].DropDownMenuItemsHide {
  display: none;
}

div[data-component="DropDownMenuItems"] {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #003057;
  padding: 0.3vw;
  text-align: center;
  top: 8vh;
  font-weight: bold;
}

/* ##### 24-08-08 Komarov */

div[data-component="CooperationMobileItems"].CooperationMobileItemsHide {
  display: none;
}

div[data-component="CooperationMobileItems"] {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.3vw;
  text-align: center;
  font-weight: bold;
  background-color: #dce5ee;
  z-index: 2000;
  border: 2px solid white;
  border-radius: 6px;
}

div[data-component="RequestsMobileItems"].RequestsMobileItemsHide {
  display: none;
}

div[data-component="RequestsMobileItems"] {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.3vw;
  text-align: center;
  font-weight: bold;
  background-color: #dce5ee;
  z-index: 2000;
  border: 2px solid white;
  border-radius: 6px;
}

/* $$$$$ 22-09-15 Komarov */
.topMenuMobileLandscape a:hover {
  color: yellow;
  list-style: underline;
}

/* $$$$$ 22-09-15 Komarov */
.topMenuMobileLandscape a {
  color: white;
}

.activeMenuLinks {
  color: yellow;
  list-style: underline;
}

/* for NavLinks topmenu that come from Smart */
.Upper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* ##### 23-03-15 Komarov */
  z-index: 2001;
}

.HomeIcon {
  color: white;
  font-size: 33px;
  font-weight: bold;
  border: none;
}

.HomeIconTablet {
  color: white;
  /* ##### 23-04-01 Komarov */
  font-size: 2em;
  font-weight: bold;
  border: none;
}

.HomeIconMobile {
  color: white;
  /* ##### 23-03-23 Komarov */
  font-size: 1.63em;
  font-weight: bold;
  border: none;
}

/* $$$$$ 22-09-15 Komarov */
.HomeIconMobileLandscape {
  color: white;
  /* ##### 23-03-23 Komarov */
  font-size: 2.33em;
  font-weight: bold;
  border: none;
  padding-left: 3vw;
}

.LangCurrency {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 2vw;
  display: flex;
  flex-direction: row;
  justify-content: space-around
}

.LangCurrencyTablet {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 2vw;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.LangCurrencyMobilePortrait {
  margin-top: auto;
  margin-bottom: auto;
  /* ##### 22-11-10 Komarov */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.LangCurrencyMobileLandscape {
  margin-top: auto;
  margin-bottom: auto;
  /* ##### 23-03-23 Komarov */
  margin-right: 4vw;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  scale: 1.1;
}

/* for NaVLinks when the page is active*/
a.active {
  color: white;
  text-decoration: underline;
  font-weight: bold;
}

.UserOutlinedIcon {
  border: none;
  font-weight: bold;
  padding-right: 0.4vw;
}

/* ALL CSS FOR MIDDLE MENU */
.middleMenu,
.nav_big {
  display: flex;
  flex-direction: row;
  /* ##### 23-05-04 Komarov */
  justify-content: space-evenly;
  width: 100%;
  /* ##### 23-11-01 Komarov */
  align-items: center;
}

.middleMenuSmallScreen {
  display: flex;
  flex-direction: row;
  /* ##### 22-11-11 Komarov */
  align-items: center;
  /* ##### 23-05-04 Komarov */
  justify-content: space-evenly;
}

.middleMenu {
  width: 100%;
  position: sticky;
  z-index: 1030;
  max-height: 10vh;
}

.middleMenuSmallScreen {
  /* ##### 22-11-10 Komarov */
  background-color: #dce5ee;
  width: 100%;
  position: sticky;
  z-index: 1030;
}

.middleMenu a {
  color: #003057;
  text-decoration: none;
  min-width: 10vw;
  font-weight: bold;
}

.middleMenu a:hover {
  font-weight: bolder;

}

.ArkturDMClogo {
  padding: 5px 15px 5px 20px;
  /* ##### 23-11-01 Komarov */
  height: 4vw;
}

/* ##### 23-03-24 Komarov */
.ArkturDMClogoSmall {
  margin-left: 1vw;
  /* ##### 23-11-02 Komarov */
  height: 8vw;
}

.ArkturDMClogoSmall.for-portrait-screen {
  margin-left: 1vw;
  height: 5vh;
}

.ArkturDMClogoSmall.for-landscape-screen {
  margin-left: 1vw;
  height: 10vh;
}

.MyAccount {
  display: flex;
  flex-direction: row;
  color: darkblue;
  font-weight: bold;
  background-color: white;
  padding-left: 5px;
  padding-right: 5px;
  border: 2px solid darkblue;
  border-radius: 8px
}

.MyAccount:hover {
  background-color: pink;
}

a.MyAccount:hover {
  text-decoration: none;
}

div.nav_big {
  justify-content: center;
}

.nav_big_link {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  list-style: none;
  padding-left: 0;
  margin-top: auto;
  margin-bottom: auto;
  max-width: 50%;
}

.nav_big_link li {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.wrapper_nav_big_link {
  margin-top: auto;
  margin-bottom: auto;
}

.nav_big_link h2 {
  display: flex;
  flex-direction: row;
  text-align: center;
  font-weight: bold;
  width: 10vw;
  line-height: 3vw;
  color: #102D69;
  /* ##### 24-09-23 Komarov */
  font-family: "Arial Narrow", sans-serif;
  font-size: 18px;
}

.nav_big_link a {
  padding-left: 1vw;
  padding-right: 1vw;
}

.nav-small-link {
  cursor: pointer;
  color: #003057;
  list-style: none;
  line-height: 10px;
  /* ##### 23-11-02 Komarov */
  margin-left: auto;
  margin-right: auto;
}

/* ##### 22-11-11 Komarov */
.nav-small-link a {
  /* ##### 24-09-23 Komarov */
  font-family: Arial, sans-serif;
  color: #003057;
  text-align: left;
  vertical-align: middle;
}

/* ##### 24-01-05 Komarov */
.nav-small-link a h4 {
  /* ##### 24-09-23 Komarov */
  font-family: Arial, sans-serif;
  color: #003057;
  text-align: left;
  vertical-align: middle;
}

/* ##### 24-06-12 Komarov */
.nav-small-link-mobile-landscape a h4 {
  /* ##### 24-09-23 Komarov */
  font-family: Arial, sans-serif;
  color: #003057;
  text-align: left;
  vertical-align: middle;
}

#sidebar-list {
  position: absolute;
  z-index: 2000;
  right: 0;
  /* $$$$$ 22-09-15 Komarov */
  padding-top: 1vh;
  padding-left: 2vw;
}

/* ##### 22-11-11 Komarov */
.hamburger-btn {
  width: 12vw;
  background-color: #DCE5EE;
  margin-top: 0.1vh;
  border: none;
  display: block;
  position: relative;
}

/* ##### 23-04-03 Komarov */
.hamburger-btn-tablet-portrait {
  width: 8vw;
  background-color: #DCE5EE;
  margin-top: 0.1vh;
  border: none;
  display: block;
  position: relative;
}

.hamburger-btn-mobile-landscape {
  /* ##### 23-03-24 Komarov */
  width: 13vh;
  background-color: #DCE5EE;
  border: none;
  display: block;
  position: relative;
}

.MenuButtonIcon {
  color: #003057;
  font-weight: bold;
  border: none;
}

.CloseMenuIcon {
  font-weight: bold;
  border-radius: 20px;
  border: none;
  color: #003057
}

.addTransition {
  background-color: #dce5ee;
  min-height: 30vh;
  position: absolute;
  z-index: 2000;
  right: 0;
  border: 2px solid white;
  border-radius: 6px;
}

.removeTransition {
  visibility: hidden;
}

.dropdown_list {
  display: none;
}

.dropdown:hover .dropdown_list {
  display: block;
  position: absolute;
  z-index: 1000;
  background-color: #BCD7EE;
  list-style-type: none;
  width: 10vw;
  padding-left: 2vw;
  /*##### 24-12-19 Komarov*/
  margin-left: 0;
  border-radius: 10px;
}

.dropdown:hover {
  border-radius: 6px;
  color: #001959;
  font-style: italic;
}

.list {
  /* ##### 24-09-23 Komarov */
  flex-direction: column;
  justify-content: center;
  background-color: #BCD7EE;
  display: block;
  border-radius: 10px;
  position: absolute;
  z-index: 1000;
  width: 15vw;

}

.list_2 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.list li {
  list-style-type: none;
  margin-bottom: 0.5vw;

}

.testLi {
  list-style-type: none;
  position: absolute;
  z-index: 1000;
  background-color: rgb(235, 214, 188);
  border: 2px solid burlywood;
  border-radius: 10px;
  /* ##### 23-11-02 Komarov */
  margin: 0 auto;
  text-align: left;
}

.toursList {
  position: relative;
  /* ##### 24-09-23 Komarov */
  font-family: Calibri, sans-serif;
  font-size: 12px;
}

.toursListLi a {
  text-decoration: none;
  color: #001959
}

.toursListLi a:hover {
  text-decoration: underline;
  color: #005AEB;
}

/* ##### 22-11-11 Komarov */
.ArkturSloganSmall {
  grid-column: 1/3;
  grid-row: 2;
  text-align: center;
}

.SmallScreenCurrencyLang {
  grid-column: 2;
  grid-row: 3;
}