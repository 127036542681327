.PCPopUpNotActive {
  display: none;
}

.PCPopUpActive {
  display: block;
  position: absolute;
  background-color: white;
  margin-top: 1vw;
  z-index: 1000;
}
