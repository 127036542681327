/* ##### 24-04-05 Komarov */
.RatesGridWrapper {
  display: grid;
  /* ##### 23-11-22 Komarov */
  grid-template-columns: 30% 5% 29% 12% 8.15% 15.95%;
  grid-auto-rows: minmax(4vw, auto);
  padding-left: 0;
  margin-bottom: 0;
}

/* ##### 24-04-13 Komarov */
.RatesGridWrapperOnRequestPrices {
  display: grid;
  /* ##### 23-11-22 Komarov */
  grid-template-columns: 30% 5% 29% 12% 24.1%;
  grid-auto-rows: minmax(4vw, auto);
  padding-left: 0;
  margin-bottom: 0;
}

.RatesGridWrapperOnRequest {
  display: grid;
  /* ##### 23-11-22 Komarov */
  grid-template-columns: 30% 34% 36%;
  padding-left: 0;
  margin-bottom: 0;
}

.Header {
  background-color: #a3caf1;
  border-radius: 0.5vw;
  border: 2px solid rgb(109, 109, 196);
}

.RoomTypeColumn {
  grid-column: 1;
  grid-row: 1/span 2;
  color: #102D69;
  font-size: 17px;
  font-family: Arial, serif;
  font-weight: bold;
  background-color: rgb(228, 228, 247);
  border-bottom: 2px solid rgb(109, 109, 196);
  border-radius: 5px;
  margin-bottom: 0;
}

.RoomTypeColumnSmallScreen {
  grid-column: 1/-1;
  grid-row: 1;
  color: #102D69;
  font-size: 17px;
  font-family: Arial, serif;
  font-weight: bold;
  background-color: rgb(199, 199, 230);
  border-radius: 5px;
  padding-top: 3vw;
  padding-bottom: 3vw;
  text-align: center;
}

.NotActive {
  display: none;
}

.GridUlDetails {
  padding-left: 0;
  border-left: 2px solid rgb(109, 109, 196);
  border-right: 2px solid rgb(109, 109, 196);
  border-radius: 5px;
}

.DetailsSmallScreen {
  display: grid;
  /* ##### 24-05-30 Komarov */
  grid-auto-rows: minmax(4vw, auto);
  border: 2px solid rgb(109, 109, 196);
  border-radius: 5px;
  justify-content: center;
  align-items: end;
}

.DetailsSmallScreen div h5:nth-child(1) {
  grid-row: 2;
  font-size: 17px;
  color: #102D69;
  font-family: Arial, serif;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.DetailsSmallScreen div h5:nth-child(2) {
  grid-row: 3;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Details {
  display: grid;
  grid-template-columns: 30% 5% 29% 12% 12% 12%;
  list-style: none;
}

.DetailsOnRequest {
  display: grid;
  grid-template-columns: 30% 34% 36%;
  list-style: none;
}

.Details h5 {
  margin-bottom: 0;
  overflow: hidden;
  text-align: center;
}

.Details h5:nth-child(1) {
  display: flex;
  justify-content: center;
  align-items: center;
}

.DetailsOnRequest h5:nth-child(1) {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
}

.RatesGridHeader {
  list-style: none;
  font-family: Arial, serif;
  color: #102D69;
  font-size: 18px;
  font-weight: bold;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 4;
  border-left: 2px solid rgb(109, 109, 196);
  text-align: center;
}

.RatesGridHeader:nth-child(1) {
  border-left: none;
}

.WrapperAvailableOptions {
  display: grid;
  grid-column: 4/-1;
  /* ##### 23-11-22 Komarov */
  grid-template-columns: 33% 23% 43%;
  margin-bottom: 0;
  text-align: center;
  border-bottom: 2px solid rgb(109, 109, 196);
}

.WrapperAvailableOptionsOnRequest {
  display: grid;
  grid-column: 4/-1;
  /* ##### 23-11-22 Komarov */
  grid-template-columns: 33% 67%;
  margin-bottom: 0;
  text-align: center;
  border-bottom: 2px solid rgb(109, 109, 196);
}


.WrapperAvailableOptionsHotelOnRequest {
  /* ##### 23-11-22 Komarov */
  margin-bottom: 0;
  text-align: center;
  border-bottom: 2px solid rgb(109, 109, 196);
}

.WrapperAvailableOptions h5:nth-child(2) {
  border-left: 2px solid rgb(109, 109, 196);
}

.WrapperAvailableOptions h5:nth-child(3) {
  border-left: 2px solid rgb(109, 109, 196);
}

.WrapperAvailableOptionsSmallScreen {
  grid-row: 3;
  margin-bottom: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.AvailableOptions {
  font-size: 15px;
  color: #102D69;
  font-family: 'Arial', serif;
  text-align: center;
}