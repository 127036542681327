.visibleCross {
  display: block;
  font-size: 14px;
  cursor: pointer;
  align-self: center;
  /* ##### 24-02-02 Komarov: центрировал крестик по вертикали */
  z-index: 10000;

}

.visibleCross:hover {
  color: slategrey;
}

.notVisibleCross {
  display: none;
}