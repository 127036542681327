.TourDetailsWrapper {
  display: flex;
  flex-direction: column;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.TourDetailsWrapper h2 {
  color: #102d69;
  font-family: "Arial", serif;
  font-size: 30px;
  font-weight: bold;
}

.TourChoiceBlockWrapper {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  margin-top: 2vh;
}

.Icons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: #102d69;
  font-family: "Arial", serif;
  font-size: 17px;
}

.TourDetailsInner {
  display: grid;
  grid-template-columns: 60% 40%;
  column-gap: 3vw;
}

.TourDetailsInnerSmallScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.GalleryTourDetails {
  margin-top: 2vw;
  width: 100%;
  overflow: auto;
  min-height: 60vh;
}

.TourBookingDetails {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 100%;
  min-height: 20vh;
  border: 2px solid rgb(206, 207, 209);
  border-radius: 6px;
  margin-top: 2vw;
}

.TourBookingDetails h3 {
  margin-bottom: 2vh;
  color: #1141a0;
  font-size: 28px;
  text-align: center;
  font-weight: bold;
  padding: 1vh;
  width: 100%;
  box-shadow: 0 0 10px rgba(141, 156, 243, 0.5);
}

.TourBookingChoice {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.TourChoiceBlock {
  display: flex;
  flex-direction: column;
}

.TourRateLoading {
  width: 285px;
  height: 250px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.TourChoiceBlock h4 {
  font-family: "Arial Narrow", serif;
  font-weight: bold;
  font-size: 17px;
  color: rgb(77, 75, 75);
}

.TourUpdateBlock {
  margin-bottom: 2vh;
  margin-top: 2vh;
  color: #1141a0;
  font-size: 28px;
  text-align: center;
  font-weight: bold;
  padding: 1vh;
  width: 100%;
  box-shadow: 0 0 10px rgba(141, 156, 243, 0.5);
}

.TourPaxChoiceWrapper {
  width: 80%;
}

.TourPaxChoiceWrapper h4 {
  font-family: "Arial Narrow", serif;
  font-weight: bold;
  font-size: 17px;
  color: rgb(77, 75, 75);
}

.TourInclusionsBlock {
  display: grid;
  grid-template-columns: 30% 70%;
  grid-column: 1 / span 2;
  width: 80%;
  border: 1px solid rgb(163, 174, 185);
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
}

.InclusionIcons {
  display: grid;
  grid-template-columns: 33% 33% 34%;
  grid-auto-rows: auto;
  grid-column: 2;
}

.TourInclusionsBlock h4 {
  font-family: "Arial Narrow", serif;
  font-weight: bold;
  font-size: 17px;
  color: rgb(77, 75, 75);
}

.TourBookingChoice h4 {
  font-family: "Arial Narrow", serif;
  font-weight: bold;
  font-size: 17px;
  color: rgb(77, 75, 75);
}

.PaxChoice {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.PaxChoice h4 {
  font-family: "Arial Narrow", serif;
  font-weight: bold;
  font-size: 17px;
  color: rgb(77, 75, 75);
}

.PaxResult {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  height: 32px;
  width: 90%;
  padding: 4px 11px;
  background-color: white;
}

.PaxResult h4 {
  color: rgb(16, 45, 105);
  font-family: "Arial Narrow", serif;
  font-size: 16px;
  cursor: pointer;
}

.DropdownButton {
  margin-top: 0.5vh;
  background-color: white;
  border: 2px solid rgb(177, 174, 174);
  border-radius: 5px;
  min-width: 200px;
}

.DropdownItem {
  font-weight: bold;
  display: flex;
  flex-direction: column;
  color: #102d69;
}

.DropdownItem:hover {
  background-color: #d7ebfc;
  text-decoration: none;
}

.DescriptionTourDetails {
  margin-left: 4vw;
  color: #102d69;
  font-size: 18px;
  text-align: justify;
  margin-top: 2vw;
  margin-bottom: 3vw;
  padding: 2vw;
  border: 1px solid lightgrey;
  border-radius: 10px;
}

.DescriptionTourDetails h4 {
  margin-bottom: 2vh;
  color: #1141a0;
  font-size: 22px;
  text-align: left;
  font-weight: bold;
}

.DescriptionTourDetails h3 {
  margin-bottom: 2vh;
  color: #1141a0;
  font-size: 28px;
  text-align: left;
  font-weight: bold;
}

.CostBreakdown {
  margin-left: 4vw;
  color: #102d69;
  font-size: 18px;
  text-align: justify;
  margin-top: 2vw;
  padding: 2vw;
  border: 1px solid lightgrey;
  border-radius: 10px;
}

.CostBreakdown h3 {
  margin-bottom: 2vh;
  color: #1141a0;
  font-size: 28px;
  text-align: left;
  font-weight: bold;
}

.TourPopUpNotActive {
  display: none;
}

.TourPopUpActive {
  display: block;
  position: absolute;
  background-color: white;
  z-index: 1000;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 15px;
}

.TourPopUpButton {
  background: #009fe3;
  color: white;
  border: 1px solid grey;
  border-radius: 2px;
  font-size: 19px;
  width: 19vw;
  height: 50px;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 6px;
}

.ChosenCalendar {
  display: grid;
  grid-template-columns: 20% 50% 30%;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 3vh;
  border: 1px solid rgb(163, 174, 185);
  border-radius: 10px;
}

.DateSelection {
  font-size: 17px;
  color: blue;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.Minus {
  color: white;
  background-color: #009fe3;
  border: 0.8vw solid #009fe3;
  border-radius: 5vw;
  font-size: 15px;
  font-weight: bolder;
  width: 40px;
  height: 40px;
}

.Plus {
  color: white;
  background-color: #009fe3;
  border: 0.8vw solid #009fe3;
  border-radius: 5vw;
  font-size: 15px;
  font-weight: bolder;
  width: 40px;
  height: 40px;
}

.DownOutlined {
  color: #d9d9d9;
  font-size: 13px;
  padding-left: 5px;
}

.RadioWrapper {
  margin-top: 20px;
}

.RadioWrapper h5 {
  color: blue;
  font-size: 19px;
  font-family: "Arial Narrow", serif;
  font-weight: bold;
  min-width: 35vw;
  margin-bottom: 15px;
}

.Radio {
  margin-top: 20px;
  min-width: 35vw;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.TourWrapperAddToBasket {
  display: grid;
  grid-template-columns: 60% 40%;
  padding-top: 3vh;
  margin-top: 0;
  column-gap: 4vw;
}

.WrapperAddToBasket h4 {
  width: 30vw;
  height: 25px;
  text-align: left;
  margin-top: 25px;
  margin-bottom: 8px;
}

.AddToCartTour {
  background: #009fe3;
  color: white;
  border: 1px solid grey;
  border-radius: 6px;
  font-weight: bold;
  font-size: 19px;
  max-height: 5vh;
  text-align: center;
  vertical-align: middle;
  padding-bottom: 1vh;
  padding-top: 1vh;
  position: relative;
  z-index: 50;
  width: 70%;
}

.AddToCartTour:hover {
  cursor: pointer;
}

.TourDetailsCost {
  display: grid;
  grid-template-columns: 20% 80%;
  list-style: none;
  background-color: white;
}

.TourDetailsCost_First {
  grid-column: 1;
  grid-row: 1 / span 2;
  color: #102d69;
  font-size: 20px;
  font-family: Arial, serif;
  font-weight: bold;
  background: rgb(228, 228, 247);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  border-radius: 10px;
}

.TourDetailsCost_Second {
  grid-column: 2;
  display: grid;
  grid-template-columns: 25% 15% 5% 20% 5% 15% 15%;
  grid-auto-rows: auto;
  font-size: 19px;
  color: #102d69;
  font-family: Arial, serif;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  border-bottom: 3px solid rgb(228, 228, 247);
}

.TourCostHeader {
  background: white;
  padding: 1.5vh;
  background: rgb(210, 227, 250);
  display: grid;
  grid-template-columns: 20% 20% 12% 4% 16% 4% 12% 12%;
  border-radius: 7px;
}

.TourCostHeader li {
  list-style: none;
  font-family: Arial, serif;
  color: #1141a0;
  font-size: 23px;
  font-weight: bold;
  margin-top: auto;
  margin-bottom: auto;
  border-left: 0.5px solid rgb(109, 109, 196);
  text-align: center;
}

.TourCostHeader li:nth-child(1) {
  border-left: none;
}
