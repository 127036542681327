.TermsNotVisible {
  display: none;
}

.TermsVisible {
  display: block;
  position: fixed;
  background-color: white;
  z-index: 5000;
  top: 0;
  overflow: scroll;
}
