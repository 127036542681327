.WrapperAddToBasket {
  min-width: 35vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.WrapperAddToBasket h4 {
  width: 30vw;
  height: 25px;
  text-align: left;
  margin-top: 25px;
  margin-bottom: 8px;
}

.AddToBasketButton {
  align-self: center;
  background: #009fe3;
  color: white;
  border: 1px solid grey;
  border-radius: 2px;
  font-weight: bold;
  font-size: 19px;
  /* ##### 23-11-22 Komarov */
  text-align: center;
  padding: 2vw 5px;
  /* ##### 23-11-22 Komarov */
  margin-top: 8px;
  margin-bottom: 8px;
  line-height: 0;
}

/* ##### 24-04-16 Komarov */
.AddToBasketButtonAllotment {
  align-self: center;
  background: #5eb82f;
  color: white;
  border: 1px solid grey;
  border-radius: 2px;
  font-weight: bold;
  font-size: 19px;
  text-align: center;
  padding: 2vw 5px;
  margin-top: 8px;
  margin-bottom: 8px;
  line-height: 0;
}

.AddToBasketButtonRequest {
  align-self: center;
  background: #b8612f;
  color: white;
  border: 1px solid grey;
  border-radius: 2px;
  font-weight: bold;
  font-size: 19px;
  text-align: center;
  padding: 2vw 5px;
  margin-top: 8px;
  margin-bottom: 8px;
  line-height: 0;
}

.AddToBasketButtonSmallScreen {
  align-self: center;
  background: #009fe3;
  color: white;
  border: 1px solid grey;
  border-radius: 2px;
  font-weight: bold;
  font-size: 17px;
  /* ##### 23-11-22 Komarov */
  text-align: center;
  vertical-align: middle;
  padding: 2vw 1vw;
  margin-bottom: 5vw;
}

/* ##### 24-04-16 Komarov */
.AddToBasketButtonSmallScreenAllotment {
  align-self: center;
  background: #5eb82f;
  color: white;
  border: 1px solid grey;
  border-radius: 2px;
  font-weight: bold;
  font-size: 17px;
  /* ##### 23-11-22 Komarov */
  text-align: center;
  vertical-align: middle;
  padding: 2vw 1vw;
  margin-bottom: 5vw;
}

.AddToBasketButtonSmallScreenRequest {
  align-self: center;
  background: #b8612f;
  color: white;
  border: 1px solid grey;
  border-radius: 2px;
  font-weight: bold;
  font-size: 17px;
  /* ##### 23-11-22 Komarov */
  text-align: center;
  vertical-align: middle;
  padding: 2vw 1vw;
  margin-bottom: 5vw;
}
