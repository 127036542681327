.TourDescriptionUl {
  margin-left: auto;
  margin-right: auto;
  margin-top: 5vh;
  font-family: 'Tahoma', serif;
}

.TourDescriptionUlSmallScreen {
  margin-left: auto;
  margin-right: auto;
  margin-top: 5vh;
  font-family: 'Tahoma', serif;
}

.descriptionLi {
  display: flex;
  flex-direction: column;
  text-align: justify;
  border: 2px solid #d4e5f7;
  border-radius: 5px;
  background: #dce5fc;
  padding: 1vw 2vw;
  color: #001959;
  margin-bottom: 1vh;
}


.descriptionLi div {
  text-align: right;
}

.TourDescriptionContent {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 2vh;
}

.TourDescriptionContentSmallScreen {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 2vh;
}

.searchFront {
  /*the container must be positioned relative:*/
  position: relative;
  display: inline-block;
}

.availableButtonSecondPage {
  padding: 0.5vw 1vw;
  font-size: 2vh;
  font-weight: bold;
  background-color: #5EB82F;
  color: white;
  border-radius: 0.5vw;
  width: 80%;
  margin-top: 3vh;
}

.availableButtonSecondPage:hover {
  cursor: pointer;
  background-color: #296807;
  color: rgb(250, 250, 141)
}


.onrequestButton {
  padding: 0.5vw 1vw;
  width: 10vw;
  font-size: 13px;
  font-weight: bold;
  color: white;
  border-radius: 0.5vw;
  background-color: rgb(117, 111, 107);
}

.TourSearchrendering_Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Ul_ItemContent {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  justify-items: center;
  padding: 0;
  width: 100%;
}

.Li_ItemContent {
  list-style-type: none;
  font-size: 2vh;
  text-align: justify;
  color: #001959;
  padding-left: 1vw;
  text-align: center;
}

.div_TourItemObj {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-end;
}

.div_TourItemObjSmallScreen {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2vh;
}

.availablePeriods {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 1vw;
  padding-bottom: 4vw;
}

.minimumRate_details {
  font-size: 12px;
  font-weight: bold;
}

.contentLoadingWheel {
  height: 3vw;
}