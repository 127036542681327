.mySearch_inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mySearch_inner button {
  /* ##### 23-05-08 Komarov */
  background-color: #005aeb;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  /* ##### 22-10-26 Komarov */
  border-radius: 1rem;
  /* ##### 22-10-27 Komarov: выровнял расположение строк формы поиска на разных мобильных устройствах */
  font-weight: bold;
  margin-top: 6.075px;
  text-align: center;
  margin-bottom: 6.75px;
  height: 2.4em;
  width: 50%;
}

.promocode_mobile {
  background-color: #e3e007;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  /* ##### 22-10-26 Komarov */
  border-radius: 1rem;
  /* ##### 22-10-27 Komarov: выровнял расположение строк формы поиска на разных мобильных устройствах */
  padding: 1.4vw 8vw;
  font-weight: bold;
  margin-top: 6.075px;
  text-align: center;
  margin-bottom: 6.75px;
  /* ##### 23-05-08 Komarov */
  height: 2.4em;
  width: 50%;
  position: relative;
  display: flex;
  justify-content: center;
}

.mySearch_inner button:hover {
  background-color: #001959;
  cursor: pointer;
}

.formOuterWrapper_inner {
  border-top: 4vw solid #96c6f0;
  border-left: 1vw solid #96c6f0;
  border-right: 1vw solid #96c6f0;
  border-bottom: 4vw solid #96c6f0;
  /* ##### 22-10-19 Komarov */
  border-radius: 0 0.5vw 0.5vw 0.5vw;
}

/* ##### 22-10-25 Komarov */
.formOuterWrapper_inner_SmallPortrait {
  /* ##### 22-10-27 Komarov: выровнял расположение строк формы поиска на разных мобильных устройствах */
  padding-top: 2vw;
  border-top: 4vw solid #96c6f0;
  border-left: 1vw solid #96c6f0;
  border-right: 1vw solid #96c6f0;
  border-bottom: 4vw solid #96c6f0;
  border-radius: 0.5vw;
}

.formOuterWrapper_inner_SmallLandscape {
  /* ##### 22-10-27 Komarov: выровнял расположение строк формы поиска на разных мобильных устройствах */
  padding-top: 2vw;
  border-top: 4vw solid #96c6f0;
  border-left: 1vw solid #96c6f0;
  border-right: 1vw solid #96c6f0;
  border-bottom: 4vw solid #96c6f0;
  border-radius: 0.5vw;
}

.noResultSearch {
  color: #001959;
  font-size: 18px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-family: Arial, Helvetica, sans-serif;
}

.datePicker_inner {
  /* ##### 24-09-23 Komarov */
  font-family: Tahoma, sans-serif;
  padding-top: 0.8vw;
  /* ##### 23-07-20 Komarov */
  border-right: solid rgb(229, 151, 0);
  border-left: solid rgb(229, 151, 0);
  border-radius: 0.4rem;
  width: 100%;
}

/* ##### 22-10-26 Komarov */
div.datePicker_inner_MobileLandscape {
  /* ##### 24-09-23 Komarov */
  font-family: Tahoma, sans-serif;
  height: 25px;
}
