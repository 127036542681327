/*##### 24-12-19 Komarov*/
.supplierWrapper {
  width: 95%;
  border: 2px solid rgb(99, 120, 189);
  border-radius: 10px;
  padding: 2vw;
  margin-left: auto;
  margin-right: auto;
}

.purecontentPage .blocks {
  border: 1px solid lightblue;
  border-radius: 10px;
  background: rgb(248, 238, 238);
  margin-top: 1vh;
  display: grid;
  align-items: center;
  grid-template-columns: 40% 30% 30%;
}

.purecontentPage .blocks_2 {
  border: 1px solid lightblue;
  border-radius: 10px;
  background: rgb(248, 238, 238);
  margin-top: 1vh;
  display: grid;
  align-items: center;
  grid-template-columns: 30% 20% 25% 25%;
}

.purecontentPage .blocks_3 {
  border: 1px solid lightblue;
  border-radius: 10px;
  background: rgb(248, 238, 238);
  margin-top: 1vh;
  display: grid;
  align-items: center;
  grid-template-columns: 15% 30% 15% 30%;
}

.purecontentPage .form-block-lf-lf-greaterThanOrEqual-Tablet-768-1024 {
  border: 1px solid lightblue;
  border-radius: 10px;
  background: rgb(248, 238, 238);
  margin-top: 1vh;
  display: grid;
  align-items: center;
  grid-template-columns: 15% 34% 15% 34%;
}

.purecontentPage .blocks_4 {
  border: 1px solid lightblue;
  border-radius: 10px;
  background: rgb(248, 238, 238);
  margin-top: 1vh;
  display: grid;
  align-items: center;
  grid-template-columns: 30% 20% 35% 15%;
}

/*##### 24-12-19 Komarov*/
.purecontentPage .blocks_5 {
  border: 1px solid lightblue;
  border-radius: 10px;
  background: rgb(248, 238, 238);
  margin-top: 1vh;
  display: grid;
  align-items: center;
  /* grid-template-columns: 15% 19% 15% 18% 15% 18%; */
  grid-template-columns: 13% 24% 10% 21% 10% 21%;
}

/*##### 24-12-19 Komarov*/
.purecontentPage .form-block-lf {
  border: 1px solid lightblue;
  border-radius: 10px;
  background: rgb(248, 238, 238);
  margin-top: 1vh;
  display: grid;
  align-items: center;
  /* grid-template-columns: 15% 19% 15% 18% 15% 18%; */
  grid-template-columns: 13% 24% 10% 21% 10% 21%;
}

.purecontentPage .form-block-lf-lf-lf-greaterThanOrEqual-Laptop-1024-1440 {
  border: 1px solid lightblue;
  border-radius: 10px;
  background: rgb(248, 238, 238);
  margin-top: 1vh;
  display: grid;
  align-items: center;
  grid-template-columns: 13% 24% 10% 21% 10% 21%;
}

/* ##### 23-10-16 Komarov */
.purecontentPage .form-block-lf {
  border: 1px solid lightblue;
  border-radius: 10px;
  background: rgb(248, 238, 238);
  margin-top: 1vh;
  padding-right: 0.8vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.FormSubmitButton {
  background: #009FE3;
  color: white;
  border: 1px solid grey;
  border-radius: 2px;
  font-weight: bold;
  font-size: 90%;
  width: 25%;
  text-align: center;
  padding-bottom: 2vh;
  padding-top: 2vh;
}

.FormSubmitButtonMobile {
  background: #009FE3;
  color: white;
  border: 1px solid grey;
  border-radius: 2px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  padding-bottom: 2vh;
  padding-top: 2vh;
}

.Supplier {
  /* ##### 23-09-06 Komarov */
  margin-top: 2vh;
  margin-bottom: 8px;
}

.purecontentPage .blocks span,
.purecontentPage .blocks_2 span,
.purecontentPage .blocks_3 span,
.purecontentPage .form-block-lf-lf-greaterThanOrEqual-Tablet-768-1024 span,
.purecontentPage .blocks_4 span,
.purecontentPage .blocks_5 span,
.purecontentPage .form-block-lf span,
.purecontentPage .form-block-lf-lf-lf-greaterThanOrEqual-Laptop-1024-1440 span {
  font-weight: bold;
  color: rgb(13, 3, 70);
  font-size: 14px;
  margin-left: 0.8vw;
  margin-right: 0.8vw;
}

.FormSubmitButton:hover {
  background: darkblue;
}
