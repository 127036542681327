.SliderCSS {
  width: 100%;
  position: relative;
  margin-left: 0;
  margin-right: 0;
  height: 55vh;
  padding: 0;
  overflow: hidden;
}

.SliderTabletPortraitCSS {
  width: 100%;
  position: relative;
  margin-left: 0;
  margin-right: 0;
  /* height: 45vh; */
  padding: 0;
  overflow: hidden;
}

.SliderMobilePortraitCSS {
  width: 100%;
  position: relative;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  overflow: hidden;
}

.SliderMobileLandscapeCSS {
  width: 100%;
  position: relative;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  overflow: hidden;
}

.Arrows {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.prev,
.next {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  top: 40%;
  width: auto;
  line-height: 16px;
  color: white;
  font-weight: bold;
  font-size: 30px;
  transition: 0.6s ease;
}

.next {
  right: 0;
}

.left {
  padding-right: 50px;
}


.prev:hover,
.next:hover {
  background-color: rgba(207, 181, 149, 0.8);
  border-radius: 7vw;
}
